import { feedbackConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";
//import { alertActions } from "./";
import { alertActions } from "./";


export const feedbackAction = {
  addContactDetails,
};

//-- Add contact details function
function addContactDetails(apiName,obj) {
  return dispatch => {
    dispatch(commonAction.request(feedbackConstants.ADD_CONTACT_DETAILS_REQUEST));

    commonService.withOutToken(apiName,obj).then(
      user => {
        dispatch(commonAction.success(feedbackConstants.ADD_CONTACT_DETAILS_SUCCESS, user));
        alert("Information send Successfully")
        // dispatch(alertActions.success("Information added Successfully"));
        // setTimeout(function () {
        //   dispatch(alertActions.clear());
        // }, 3000);
      },
      error => {
        dispatch(commonAction.failure(feedbackConstants.ADD_CONTACT_DETAILS_FAILURE, error));
        console.log("err is ==================",error)
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}