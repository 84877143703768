import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Faq = () => {
    const [siteConstants, setSiteConstants] = useState([])

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language]);

    const [faq, setFaq] = useState([
        { 'id': 'One', 'ques1': 'What are the services related with CarHunt?', 'ans1': 'DTK CRM is a full CRM & ILM (Internet Lead Management) platform that allows dealers to manage internet leads and customer follow-ups. We serve BHPH, independent, small franchise, RV, and powersports dealers.' },
        { 'id': 'Two', 'ques2': 'What about security ?', 'ans2': 'There is full security, your clients only for yours.' },
        { 'id': 'Three', 'ques3': 'Can I use monthly basis?', 'ans3': 'Yes, you can.' },
        // { 'id': 'Four', 'ques4': 'Do we have any demo ?', 'ans4': 'Yes, you can use http://www.dtkcrm.ca:8080' },
    ])

    return (
        <>
            {/* <!-- breadcrumb-area --> */}
            <section class="breadcrumb-area breadcrumb-bg" data-background="assets/img/bg/breadcrumb_bg.jpg">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-content text-center">
                                <h2>{siteConstants.LABEL_FAQ}</h2>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">{siteConstants.LABEL_FAQ}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- breadcrumb-area-end --> */}


            <section className="faq-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <div className="inner-faq-sec">
                                <div className="accordion" id="accordionExample">
                                    {faq.map((data, index) => {
                                        let no = index + 1;
                                        let ques = 'ques' + no;
                                        let ans = 'ans' + no;

                                        return (
                                            <div className="card" key={index}>
                                                <div className="card-header" id={'heading' + data.id}>
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={'#collapse' + data.id} aria-expanded="true"
                                                            aria-controls={'collapse' + data.id}>{data[ques]}</button>
                                                    </h2>
                                                </div>

                                                <div id={'collapse' + data.id} className="collapse" aria-labelledby={'heading' + data.id} data-parent="#accordionExample">
                                                    <div className="card-body">{data[ans] !== undefined ? data[ans] : ''}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq;