import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { feedbackAction, inventoryAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function ContactUs() {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '', email: '', message: '', vehicleVin: '', vehicleMake: '', vehicleModel: '', vehicleYear: '', contactNo: ''
  });
  const [errState, setErrState] = useState({});
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState([])
  const [make, setMake] = useState([])
  const [model, setModel] = useState([]) //-- category

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onChangeHandlerDDL = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const { language } = useSelector(
    state => ({
      language: state.language
    })
  );
  const modelList = useSelector((state) => state.model);
  const makeList = useSelector((state) => state.make);
  const errInterval = (name) => {
    let target = name + 'Err'

    setTimeout(() => {
      setErrState({ ...errState, [target]: '' })
    }, 5000);
  }

  const handleErr = (name, msg) => {
    let target = name + 'Err';
    setErrState((state) => {
      return { ...state, [target]: msg }
    });

    errInterval(name);
  }

  const onSubmit = (e) => {
    let isValidated = true;
    e.preventDefault();
    // console.log("formData",formData)
    if (formData.name === '') {
      handleErr('name', 'ERR_BLANK_USER_NAME')
      isValidated = false;
    }
    if (formData.email === '') {
      handleErr('email', 'ERR_BLANK_EMAIL')
      isValidated = false;
    }
    if (formData.contactNo === '') {
      handleErr('contactNo', 'ERR_BLANK_CONTACT')
      isValidated = false;
    }
    if (formData.vehicleVin === '') {
      handleErr('vehicleVin', 'ERR_BLANK_VEHICLE_VIN')
      isValidated = false;
    }
    if (formData.vehicleMake === '') {
      handleErr('vehicleMake', 'ERR_BLANK_VEHICLE_MAKE')
      isValidated = false;
    }
    if (formData.vehicleModel === '') {
      handleErr('vehicleModel', 'ERR_BLANK_VEHICLE_MODEL')
      isValidated = false;
    }
    if (formData.vehicleYear === '') {
      handleErr('vehicleYear', 'ERR_BLANK_VEHICLE_YEAR')
      isValidated = false;
    }
    if (isValidated) {
      let obj = { "name": formData.name, "email": formData.contactNo, "contactNo": formData.contactNo, "message": formData.message, "vehicleVin": formData.vehicleVin, "vehicleMake": formData.vehicleMake, "vehicleModel": formData.vehicleModel, "vehicleYear": formData.vehicleYear }
      dispatch(feedbackAction.addContactDetails(apiConstants.CONTACT_US, obj));
    }
  }

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language])

  useEffect(() => {
    if (Object.keys(modelList).length > 0) {
      setModel(modelList.modelList.data);
      // setMake(modelList.modelList.data);
    }
  }, [modelList])

  useEffect(() => {
   // if (makeList && makeList.makeList ) {
     if (Object.keys(makeList).length > 0) {
        // console.log("TEST11 ", makeList)
        // console.log("TEST12 ", modelList)
        setMake(makeList.makeList.data);
      }
   // }
  }, [makeList])

  // useEffect(() => {
  //   console.log("ASDD AS ", makeList)
  // })

  useEffect(() => {
    dispatch(inventoryAction.getAllActiveCategory(apiConstants.GET_ALL_ACTIVE_CATEGORIES));

    dispatch(inventoryAction.getAllActiveMake(apiConstants.GET_ALL_ACTIVE_MAKES));
  }, [])
  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr;
      if (alert.type === 'alert-success') {
        tempErr = { 'type': alert.type, 'message': alert.message }
      }
      else {
        let keys = Object.keys(alert.message.errors)
        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 13000);
    }
  }, [alert]);



  return (
    <>

      <main>

        {/* <!-- breadcrumb-area --> */}
        <section class="breadcrumb-area breadcrumb-bg" data-background="assets/img/bg/breadcrumb_bg.jpg">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="breadcrumb-content text-center">
                  <h2>Contact Us</h2>
                  <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                      <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}

        {/* <!-- contact-area --> */}
        <section class="contact-area pt-40">
          <div class="container">
            <div class="row">
              <div class="col-xl-5 col-lg-6">
                <div class="contact-info-wrap">
                  <div class="section-title text-left mb-25">
                    <span class="sub-title">contact information</span>
                    <h2 class="title">Our Address</h2>
                    <div class="line"><img src="assets/img/images/title_line.png" alt="" /></div>
                  </div>
                  <p>Contact information is the data you provide that lets recruiters get in touch with you. For all professionals.</p>
                  <ul class="contact-info-list">
                    <li>
                      <div class="icon"><i class="fas fa-map-marker-alt"></i></div>
                      <div class="content">
                        <h5>Company Office</h5>
                        <p>CarHunt Technologies Ltd. Surrey, British Columbia</p>
                      </div>
                    </li>
                    <li>
                      <div class="icon"><i class="fas fa-phone-alt"></i></div>
                      <div class="content">
                        <h5>Contact Number</h5>
                        <p><a href="tel:778-302-9099">778-302-9099</a></p>
                      </div>
                    </li>
                    <li>
                      <div class="icon"><i class="far fa-envelope"></i></div>
                      <div class="content">
                        <h5>Ask your question</h5>
                        <p><a href="mailto:info@carhunt.ca">info@carhunt.ca</a></p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-7 col-lg-6">
                <div class="contact-form-wrap">
                  <div class="login-wrap">
                    <h3 class="widget-title">Send your <span>request</span></h3>
                    <form action="#" class="login-form">
                      <div class="form-grp">
                        <label for="name">Your Name <span>*</span></label>
                        <input type="text" id="name" name="name" placeholder="Jon Deo" onChange={(e) => onChangeHandler(e)} />
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.nameErr]}</span>
                      <div class="form-grp">
                        <label for="email">Your Email <span>*</span></label>
                        <input type="email" id="email" name="email" placeholder="info@car.com" onChange={(e) => onChangeHandler(e)} />
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.emailErr]}</span>
                      <div class="form-grp">
                        <label for="contactNo">Your Contact No <span>*</span></label>
                        <input type="text" id="contactNo" name="contactNo" placeholder="0000" onChange={(e) => onChangeHandler(e)} />
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.contactNoErr]}</span>
                      <div class="form-grp">
                        <label><b>Vehicle Information :</b> </label>
                      </div>
                      <div class="form-grp">
                        <label for="vehicleVin">Vin or stock # <span>*</span></label>
                        <input type="text" id="vehicleVin" name="vehicleVin" placeholder="Please Provide Vin or Stock #" onChange={(e) => onChangeHandler(e)} />
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.vehicleVinErr]}</span>
                      <div class="form-grp">
                        <label for="vehicleYear">Year <span>*</span></label>
                        <input type="text" id="vehicleYear" name="vehicleYear" placeholder="Please Provide Vehicle Year" onChange={(e) => onChangeHandler(e)} />
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.vehicleYearErr]}</span>
                      <div class="form-grp">
                        <label for="vehicleMake">Make <span>*</span></label>
                        <br />
                        <select name="vehicleMake" id="vehicleMake"  onChange={(e) => onChangeHandlerDDL(e)} style={{ "width":"100%", "border": "1px solid #e1e1e1", "borderRadius": "6px",  "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080"}} >
                          <option value="">Select Make</option>
                          {

                            make.map((cat, index) => {
                              return <option value={cat._id}>{cat.makeTitle}</option>
                            })
                          }
                        </select>

                        {/* <input type="text" id="vehicleMake" name="vehicleMake" placeholder="Please Provide Vehicle Make" onChange={(e) => onChangeHandler(e)} /> */}
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.vehicleMakeErr]}</span>
                      <div class="form-grp">
                        <label for="vehicleModel">Model <span>*</span></label>
                        <br />
                        {/* class="selected" */}
                        <select name="vehicleModel"  onChange={(e) => onChangeHandlerDDL(e)} style={{ "width":"100%", "border": "1px solid #e1e1e1", "borderRadius": "6px",  "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080"}} >
                          <option value="">Select Model</option>
                          {
                            model.map((cat, index) => {
                              return <option value={cat._id}>{cat.categoryTitle}</option>
                            })
                          }
                        </select>
                        {/* <input type="text" id="vehicleModel" name="vehicleModel" placeholder="Please Provide Vehicle Model" onChange={(e) => onChangeHandler(e)} /> */}
                      </div>
                      <span className="error-text text-danger">{siteConstants[errState.vehicleModelErr]}</span>
                      <div class="form-grp">
                        <label for="message">Your Massage <span>*</span></label>
                        <textarea name="message" id="message" onChange={(e) => onChangeHandler(e)} placeholder="Hello..."></textarea>
                      </div>
                      <button class="btn" onClick={(e) => onSubmit(e)}>Submit</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- contact-area-end --> */}

        {/* <!-- contact-map --> */}
        <div id="contact-map"></div>
        {/* <!-- contact-map-end --> */}

        {/* <!-- footer-brand-area --> */}
        <div class="footer-brand-area white-bg">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-4 col-md-6 order-2 order-md-0">
                        <div class="footer-logo" style={{backgroundColor: 'cornsilk'}}>
                            <a href="/"><img src="/assets/img/logo/highlighted-contact_number.png" alt="" /></a>
                        </div>
                    </div>
              <div class="col-lg-8 col-md-6">
                <div class="row footer-brand-active">
                  <div class="col">
                    <div class="footer-brand-item">
                      <a href="#"><img src="assets/img/brand/footer_brand01.png" alt="" /></a>
                    </div>
                  </div>
                  <div class="col">
                    <div class="footer-brand-item">
                      <a href="#"><img src="assets/img/brand/footer_brand02.png" alt="" /></a>
                    </div>
                  </div>
                  <div class="col">
                    <div class="footer-brand-item">
                      <a href="#"><img src="assets/img/brand/footer_brand03.png" alt="" /></a>
                    </div>
                  </div>
                  <div class="col">
                    <div class="footer-brand-item">
                      <a href="#"><img src="assets/img/brand/footer_brand04.png" alt="" /></a>
                    </div>
                  </div>
                  <div class="col">
                    <div class="footer-brand-item">
                      <a href="#"><img src="assets/img/brand/footer_brand05.png" alt="" /></a>
                    </div>
                  </div>
                  <div class="col">
                    <div class="footer-brand-item">
                      <a href="#"><img src="assets/img/brand/footer_brand03.png" alt="" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer-brand-area-end --> */}

      </main>

      {/* {alertRes !== '' ? <Alert variant={alertRes.type === 'alert-danger' ? 'danger' : 'success'}
        className="alertBox">{alertRes.message} </Alert> : ''}
      <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>{siteConstants.LABEL_CONTACT_US}</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-page-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="right-contact-sec">
                <h2>{siteConstants.LABEL_CONTACT_DETAILS}</h2>

                <ul>
                  <li>
                    <p><strong>{siteConstants.LABEL_ADDRESS}</strong></p>
                    <a href="/#"><img src="assets/images/location.png" alt='' />CarHunt Technologies Ltd.
Surrey, British Columbia</a>
                  </li>
                  <li>
                    <p><strong>{siteConstants.LABEL_EMAIL}</strong></p>
                    <a href="/#"><img src="assets/images/multimedia.png" alt='' />david@dtkcrm.ca</a>
                  </li>
                  <li>
                    <p><strong>{siteConstants.LABEL_PHONE}</strong></p>
                    <a href="/#"><img src="assets/images/phone.png" alt='' />778-302-9099</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}