import React from 'react'
import { Nav } from 'react-bootstrap';
import {history} from '../../../helpers';

export default function Footer() {

    return (
      <footer class="footer-bg" data-background="/assets/img/carhunt/footer_bg.jpg">
      <div class="footer-top-area">
          <div class="container">
              <div class="row">
                  <div class="col-lg-4">
                      <div class="footer-widget black-bg mb-50">
                          <div class="fw-title mb-30">
                              <h4 class="title">Contact Us</h4>
                          </div>
                          {/* <div class="footer-text mb-45">
                              <p>There are many variations of passaes of orem ipsum available, but the majority have in some form, by ipsum
                              injected</p>
                          </div> */}
                          <div class="footer-contact">
                              <ul>
                                  <li>
                                      <div class="icon"><i class="fas fa-phone"></i></div>
                                      <div class="content">
                                          <span>PHONE NUMBER</span>
                                          <p><a href="tel:778-302-9099">778-302-9099</a></p>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="icon"><i class="fas fa-envelope"></i></div>
                                      <div class="content">
                                          <span>EMAIL ADDRESs</span>
                                          <p><a href="mailto:info@carhunt.ca">info@carhunt.ca</a></p>
                                      </div>
                                  </li>
                                  <li>
                                      <div class="icon"><i class="fas fa-map-marker-alt"></i></div>
                                      <div class="content">
                                          <span>address</span>
                                          <p>CarHunt Technologies Ltd. Surrey, British Columbia</p>
                                      </div>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-8">
                      <div class="footer-right-wrap">
                          <div class="row justify-content-between">
                              <div class="col-lg-3 col-md-3 col-sm-6">
                                  <div class="footer-widget mb-50">
                                      <div class="fw-title mb-30">
                                          <h4 class="title">Our Info</h4>
                                      </div>
                                      <div class="fw-link">
                                          <ul>
                                              <li><a href="/about-us">About Us</a></li>
                                              <li><a href="/inventory">Inventory</a></li>
                                              <li><a href="/faq">FAQ</a></li>
                                              <li><a href="/contact-us">Contact</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-lg-3 col-md-3 col-sm-6">
                                  <div class="footer-widget mb-50">
                                      <div class="fw-title mb-30">
                                          <h4 class="title">Quick Link</h4>
                                      </div>
                                      <div class="fw-link">
                                          <ul>
                                              <li><a href="http://dtkcrm.ca">DTK CRM</a></li>
                                              <li><a href="https://gomotor.ca">GoMotor</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-xl-5 col-lg-6 col-md-6">
                                  <div class="footer-widget mb-50">
                                      {/* <div class="fw-title mb-30">
                                          <h4 class="title">Subscribe Us</h4>
                                      </div> */}
                                      {/* <div class="footer-newsletter">
                                          <form action="#">
                                              <input type="email" placeholder="Your Mail..." />
                                              <button><i class="fas fa-angle-double-right"></i></button>
                                          </form>
                                      </div> */}
                                      <div class="footer-social">
                                          <ul>
                                              <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                              <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                              <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                              <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="footer-payment-method-wrap">
                              <div class="content">
                                  <p>We know that honesty and transparency friendly customer service</p>
                              </div>
                              <div class="payment-card">
                                  <img src="/assets/img/images/payment_card.png" alt="" />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="copyright-area">
          <div class="container">
              <div class="row align-items-center">
                  <div class="col-md-8">
                      <div class="copyright-text">
                          <p>Copyright © 2024. All rights reserved. by Carhunt</p>
                      </div>
                  </div>
                  <div class="col-md-4">
                      <button class="scroll-top scroll-to-target" data-target="html">
                          <i class="fas fa-angle-up"></i>
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </footer>
    )
}
