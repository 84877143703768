import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { feedbackAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';

export default function ContactUs() {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '', email: '', contact_no:'', message: ''
  });
  const [errState, setErrState] = useState({});
  const [alertRes, setAlertRes] = useState('');
  const [siteConstants, setSiteConstants] = useState([])

  const onChangeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const { language } = useSelector(
    state => ({
      language: state.language
    })
  );

  const errInterval = (name) => {
    let target = name + 'Err'
    setTimeout(() => {
      setErrState({ ...errState, [target]: '' })
    }, 2000);
  }

  const handleErr = (name, msg) => {
    let target = name + 'Err';
    setErrState((state) => {
      return { ...state, [target]: msg }
    });
    errInterval(name);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (formData.name === '') {
      handleErr('name', 'ERR_BLANK_USER_NAME')
    }
    if (formData.contact_no === '') {
      handleErr('contact_no', 'ERR_BLANK_CONTACT')
    }
    if (formData.email === '') {
      handleErr('email', 'ERR_BLANK_EMAIL')
    }
    else {
      let obj = { "name": formData.name, "email": formData.email, "contact_no": formData.contact_no, "message": formData.message }
      dispatch(feedbackAction.addContactDetails(apiConstants.DEMO, obj));

      alert("Email send successfully.");
    }
  }

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language])

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr;
      if (alert.type === 'alert-success') {
        tempErr = { 'type': alert.type, 'message': alert.message }
      }
      else {
        let keys = Object.keys(alert.message.errors)
        tempErr = { 'type': alert.type, message: alert.message.errors[keys] }
      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 3000);
    }
  }, [alert]);



  return (
    <>
      {alertRes !== '' ? <Alert variant={alertRes.type === 'alert-danger' ? 'danger' : 'success'}
        className="alertBox">{alertRes.message} </Alert> : ''}
      <section className="banner-inner" style={{ backgroundImage: `url("/assets/images/banner-faq.jpg")` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Request for Demo</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-page-sec">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 col-md-12">
              <div className="right-contact-sec">
                <h2>Instructions</h2>

                <p>
                  This is testing
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="heading-box">
                <h2>Request for Demo</h2>
                <p className="sub-heading"></p>
              </div>
              <div className="contact-us-sec">
                <form>
                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-user"></i>
                    </div>
                    <input type="text" className="form-control" placeholder={siteConstants.LABEL_NAME} name="name" onChange={(e) => onChangeHandler(e)} />
                  </div>
                  <span className="error-text text-danger">{siteConstants[errState.nameErr]}</span>

                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-envelope"></i>
                    </div>
                    <input type="email" className="form-control" placeholder={siteConstants.LABEL_EMAIL} name="email" onChange={(e) => onChangeHandler(e)} />
                  </div>
                  <span className="error-text text-danger">{siteConstants[errState.emailErr]}</span>
                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-envelope"></i>
                    </div>
                    <input type="contact_no" className="form-control" placeholder="Contact No" name="contact_no" onChange={(e) => onChangeHandler(e)} />
                  </div>
                  <span className="error-text text-danger">{siteConstants[errState.contact_noErr]}</span>
                  <div className="input-group mb-3">
                    <div className="input-group-img">

                      <i className="far fa-comment-alt"></i>
                    </div>
                    <textarea className="form-control" id="form6Example7" rows="4" placeholder={siteConstants.GEN_MESSAGE} name="message" onChange={(e) => onChangeHandler(e)}></textarea>
                  </div>

                  <button type="button" onClick={(e) => onSubmit(e)} className="btn btn-primary mt-3">{siteConstants.LABEL_SUBMIT}</button>
                  <br />
                  {/* <span className="error-text text-success"><b>Email Sent</b></span> */}
                </form>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}