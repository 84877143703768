import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import FooterBrandArea from '../Common/Footer/FooterBrandArea';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryAction } from '../../actions';
import { apiConstants } from '../../constants';
import InventoryRightBarSearch from '../Common/InventoryRightBarSearch';
import { useParams } from 'react-router-dom';

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/products/';

const InventoryDetails = ({ props }) => {
  // const { match } = props;
  const { id } = useParams();
  let productId = id;
  const [inventory, setInventory] = useState([])
  const inventoryDetails = useSelector((state) => state.inventory);
  // console.log("inventoryDetails", inventoryDetails)
  // const productId = "6596c3089b0ed22ee216dd3d"
  //6596eb1e1cbe4a0ccfde8216
  const dispatch = useDispatch();
  useEffect(() => {
    const obj = {};
    obj.productId = productId;
    dispatch(inventoryAction.getSingleProduct(apiConstants.GET_SINGLE_PRODUCT, obj));

  }, [])

  useEffect(() => {
    if (Object.keys(inventoryDetails).length > 0) {
      setInventory(inventoryDetails.inventoryDetails);
    }
  }, [inventoryDetails])

  return (
    <main>

      {/* <!-- breadcrumb-area --> */}
      <section class="breadcrumb-area breadcrumb-bg inventory-details-breadcrumb" data-background="/assets/img/bg/breadcrumb_bg.jpg">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb-content text-center">
                <h2>{inventory.make+" - "+inventory.model}</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{inventory.car_type}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb-area-end --> */}

      {/* <!-- inventory-details-slide --> */}
      <div class="inventory-details-slide">
        <div class="container-fluid p-0 fix">
          <div class="row no-gutters inv-details-active">
            <div class="col-lg-4">
              <div class="inv-details-slide-item">
                <img src={inventory.images} alt="" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="inv-details-slide-item">
                <img src={inventory.images} alt="" />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="inv-details-slide-item">
                <img src={inventory.images} alt="" />
              </div>
            </div>
            {/* <div class="col-lg-4">
              <div class="inv-details-slide-item">
                <img src="/assets/img/images/inv_details_slide_item02.jpg" alt="" />
              </div>
            </div> */}
          </div>
        </div>
        <div class="inv-details-meta-wrap">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 col-sm-7">
                <div class="inv-details-meta">
                  <div class="price-top">
                    <div class="price">{(inventory.price >0) ? inventory.price :''}<span></span></div>
                    <div class="tag"><a href="#">{inventory?.status?.toUpperCase()}</a></div>
                  </div>
                  <div class="location">
                    <p><i class="flaticon-placeholder"></i> carhunt.ca<br />778-302-9099</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-5">
                <div class="inv-details-meta-right">
                  <ul>
                    <li><a href="#"><i class="fas fa-share-alt"></i></a></li>
                    <li><a href="#"><i class="fas fa-random"></i></a></li>
                    <li><a href="#"><i class="fas fa-print"></i></a></li>
                    <li><a href="#"><i class="fas fa-heart"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- inventory-details-slide-end --> */}

      {/* <!-- inventory-list-area --> */}
      <section class="inventory-details-area gray-lite-bg pt-120 pb-120">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              <div class="inventory-features mb-30">
                <div class="inv-details-title">
                  <h5>Inventory Features</h5>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Body Type :</h6>
                      <span>Coupe</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Make :</h6>
                      <span>{inventory.make}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Transmission :</h6>
                      <span>{inventory.transmission}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Year :</h6>
                      <span>{inventory.year}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Condition :</h6>
                      <span>{inventory.status}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Fuel Type :</h6>
                      <span>{inventory.fuel_type}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Drive Type :</h6>
                      <span>{inventory.drive_type}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Doors :</h6>
                      <span>{inventory.doors}-door</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Category :</h6>
                      <span>{inventory.category}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Vin :</h6>
                      <span>{inventory.vin}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Stock Number :</h6>
                      <span>{inventory.stock_number}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Make :</h6>
                      <span>{inventory.make}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Model :</h6>
                      <span>{inventory.model}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Car Type :</h6>
                      <span>{inventory.car_type}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Van Type :</h6>
                      <span>{inventory.van_type ? inventory.van_type : 'NA'}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Truck Type :</h6>
                      <span>{inventory.truck_type ? inventory.truck_type : 'NA'}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Trim :</h6>
                      <span>{inventory.trim}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Kilometres :</h6>
                      <span>{inventory.kilometres}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Mileage :</h6>
                      <span>{inventory.mileage ? inventory.mileage : 'NA'}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Engine :</h6>
                      <span>{inventory.engine}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Fuel Type :</h6>
                      <span>{inventory.fuel_type}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Mpg City :</h6>
                      <span>{inventory.mpg_city ? inventory.mpg_city : 'NA'}</span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Mpg Highway :</h6>
                      <span>{inventory.mpg_highway ? inventory.mpg_highway : 'NA'}</span>
                    </div>
                    <div class="inventory-features-item">
                      <h6>Exterior Colour :</h6>
                      <span>{inventory.exterior_colour ? inventory.exterior_colour : 'NA'}</span>
                    </div>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 col-sm-4">
                    <div class="inventory-features-item">
                      <h6>Interior Colour :</h6>
                      <span>{inventory.interior_colour ? inventory.interior_colour : 'NA'}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="inventory-details-description mb-30">
                <div class="inv-details-title">
                  <h5>Description</h5>
                </div>
                <p>{inventory.options}</p>
                {/* <div class="vehicle-history">
                  <h5>Vehicle history</h5>
                  <p>As for Passepartout thought Mr. Fogg’s manovre simply gorious. The captain had said “betwen eleven and twve knots,” and
                    the Henrietta said the confirmed his she said nothing prdiction. There are many variations of passages of Lorem Ipsum
                    available, but the majority.</p>
                </div> */}
                {/* <div class="inv-details-img">
                  <div class="row">
                    <div class="col-sm-6">
                      <img src="/assets/img/images/inv_details_img01.jpg" alt="" />
                    </div>
                    <div class="col-sm-6">
                      <img src="/assets/img/images/inv_details_img02.jpg" alt="" />
                    </div>
                  </div>
                </div> */}
                {/* <p>Aliquet cursus quam. Pellentesque pulvin condimentum dictum, sapien auctor tortoris vulput sapien tortor velit. Sed
                  nulla congue euqua moltie grvida ipsum. Curabitr lacus vitae tellus lacinia pretium. Proin vestibulum sollcitudin
                  tortor, quis auctor mi rutrum.</p>
                <div class="inv-details-list">
                  <ul>
                    <li>Sapien auctor tortoris vulputate sapien ?</li>
                    <li>Curabitr lacus vitae tellus lacinia pretium vulputate ?</li>
                    <li>Tellus orci auctor augue mauris ?</li>
                  </ul>
                </div>
                <p>Aliquet cursus quam. Pellentesque pulvin condimentum dictum, sapien auctor tortoris vulput sapien tortor velit. Sed
                  nulla congue euqua moltie grvida ipsu Curabitr lacus,</p> */}
              </div>
              <div class="inventory-details-location mb-30">
                <div class="inv-details-title">
                  <h5>Inventory Features</h5>
                  <p class="location">Wast San Park St, New york, NY 98 <i class="flaticon-placeholder-1"></i></p>
                </div>
                <div class="inv-details-map">
                  <img src="/assets/img/bg/map2.png" alt="" />
                </div>
              </div>
              <div class="inventory-review-wrap mb-30">
                <div class="inv-details-title">
                  <div class="review-top-left">
                    <h5>2 Reviews</h5>
                    <div class="review">
                      <div class="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <span>( 4.5 out of 5 )</span>
                    </div>
                  </div>
                  {/* <p class="write-review"><a href="#">Write a Review</a></p> */}
                </div>
                <div class="blog-comment">
                  <ul>
                    <li>
                      <div class="single-comment">
                        <div class="comment-avatar-img">
                          <img src="/assets/img/blog/comment_avatar01.jpg" alt="img" />
                        </div>
                        <div class="comment-text">
                          <div class="comment-avatar-info">
                            <div class="left">
                              <h5>Daisy Waterstone. <span class="comment-date">January 13, 2024</span></h5>
                              <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </div>
                            </div>
                            {/* <a href="#" class="comment-reply-link">Reply _</a> */}
                          </div>
                          <p>Aliquet cursus quam. Pellentesque pulvin condimentum dictum, sapien auctor tortoris vulput sapien tortor velit. Sed
                            nulla congue Curabitr.</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="single-comment">
                        <div class="comment-avatar-img">
                          <img src="/assets/img/blog/comment_avatar02.jpg" alt="img" />
                        </div>
                        <div class="comment-text">
                          <div class="comment-avatar-info">
                            <div class="left">
                              <h5>Hamil Alexander. <span class="comment-date">January 13, 2024</span></h5>
                              <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                              </div>
                            </div>
                            {/* <a href="#" class="comment-reply-link">Reply _</a> */}
                          </div>
                          <p>Aliquet cursus quam. Pellentesque pulvin condimentum dictum, sapien auctor tortoris vulput sapien tortor velit. Sed
                            nulla congue Curabitr.</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div class="inventory-review-form">
                <div class="inv-details-title">
                  <h5>Write a Review</h5>
                </div>
                <div class="review-form-wrap">
                  <span>Your Rating & Review</span>
                  <div class="rating">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                  <form action="#">
                    <input type="text" placeholder="Your Title" />
                    <textarea name="message" id="message" placeholder="Your Review"></textarea>
                    <button class="btn">Send Review</button>
                  </form>
                </div>
              </div> */}
            </div>
            <div class="col-lg-4">
              <InventoryRightBarSearch />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- inventory-list-area-end --> */}



      {/* <!-- footer-brand-area --> */}
      <FooterBrandArea />
      {/* <!-- footer-brand-area-end --> */}

    </main>
  );
}

export default InventoryDetails;