export const HINDI = {
    SITE_NAME: 'Admin',
    //-- Error Messages
    ERR_BLANK_USER_NAME: 'Enter user name',
    ERR_BLANK_USER_PASSWORD: 'Enter password',
    ERR_BLANK_EMAIL: 'Enter a email address',
    ERR_BLANK_CONTACT: 'Enter a contact no',
    ERR_BLANK_NEW_PASSWORD: 'Enter a new password',
    ERR_BLANK_CONFIRM_PASSWORD: 'Enter a Confirm password',
    ERR_BLANK_PASSWORD_NOT_MATCH: 'Password not matched',
    ERR_CAN_NOT_BLANK: 'Cannot be empty',
    ERR_ONLY_LETTERS: 'Only letters',
    ERR_EMAIL_NOT_VALID: 'Email is not valid',
    ERR_ONLY_NUMBERS: 'Only Number',
    ERR_MAX_LENGTH: "Max length is 3 only",

    //-- General Messages
    GEN_ARE_YOU_SURE: 'Are you sure ?',
    GEN_NO_RECORD: 'No Record Found',
    GEN_ADD_USER: 'Add User',
    GEN_EDIT_USER: 'Edit User',
    GEN_SEARCH: 'Search',
    GEN_FIRST: 'First',
    GEN_LAST: 'Last',
    GEN_USERS: 'Users',
    GEN_DASHBOARD: 'Dashboard',
    GEN_DESCRIPTION: 'Description',
    GEN_TYPE: 'Type',
    GEN_VALUE: 'Value',
    GEN_REMARK: 'Remark',
    GEN_NUMBER: 'No',
    GEN_TITLE: 'Title',
    GEN_IMAGE: 'Image',
    GEN_NAME: 'Name',
    GEN_MESSAGE: 'Message',
    GEN_VIEW_DETAILS: 'View Details',
    GEN_PLEASE_SELECT: 'Please select',
    GEN_EXPIRY_DATE: 'Expiry Date',
    GEN_COUPON_TOKEN: 'Coupon #',
    GEN_LEGAL: 'Legal',

    //-- Headings
    LABEL_NAME: 'Name',
    LABEL_USER_NAME: 'User Name',
    LABEL_F_NAME: 'First Name',
    LABEL_L_NAME: 'Last Name',
    LABEL_EMAIL: 'Email',
    LABEL_CONTACT_NO: 'Contact No',
    LABEL_PASSWORD: 'Password',
    LABEL_PHONE: 'Phone',
    LABEL_STATUS: 'Status',
    LABEL_ORDER_TYPE : 'Order Type',
    LABEL_ACTION: 'Action',
    LABEL_CLOSE: 'Close',
    LABEL_REMEMBER_ME: 'Remember Me',
    LABEL_FORGET_PASSWORD: 'Forget Password',
    LABEL_FORGET_PASSWORD_MESSAGE: 'Enter your email address below and we’ll send you updated password on your email.',
    LABEL_BACK_TO: 'Back to',
    LABEL_LOGIN: 'Login',
    LABEL_USER_MGT: 'Users Management',
    LABEL_SETTINGS: 'Settings',
    LABEL_GENERAL_SETTINGS: 'General Settings',
    LABEL_ADMIN_DASHBOARD: 'Admin Dashboard',
    LABEL_CHANGE_PASSWORD: 'Change Password',
    LABEL_NEW_PASSWORD: 'New Password',
    LABEL_CONFIRM_PASSWORD: 'Confirm Password',
    LABEL_OLD_PASSWORD: 'Old Password',
    LABEL_PROFILE: 'Profile',
    LABEL_ADDRESS: 'Address',
    LABEL_UPLOAD_IMAGE: 'Upload Image',
    LABEL_USER_DOCUMENT: 'User Document',
    LABEL_IDENTITY_PROOF: 'Identity Image',
    LABEL_DETAILS: 'Identity Details',
    LABEL_LICENCE_NUMBER: 'License Number',
    LABEL_IDENTIFY_VERIFIED: 'Verified',
    LABEL_LICENSE: 'License',

    /* Trade */

    /* Users */
    LABEL_COMPANY_NAME: 'Company Name',
    LABEL_CERTIFICATION_NO: 'Certification Number',
    LABEL_CELL_PHONE: 'Cell Phone',
    LABEL_ROLES: 'Roles',
    LABEL_APPROVAL: 'Approval',
    LABEL_PUSH_MESSAGE: 'Push Message',
    LABEL_USER_DETAILS: 'User Details',
    LABEL_USER_CREATED_DATE: 'Created Data',
    LABEL_USER_REGISTRATION_DATE: 'Registration Date',
    LABEL_USER_APPROVAL_DATE: 'Approval Date',
    LABEL_NUMBER_OF_BUYING: 'Number of Buying',
    LABEL_NUMBER_OF_SELLING: 'Number of Selling',
    LABEL_NUMBER_OF_SATISFACTION: 'Number of Satisfaction',
    LABEL_BANK_ACCOUNTS: 'Bank Accounts',
    LABEL_BANK: 'Bank',
    LABEL_ACCOUNT: 'Account',
    LABEL_AADHAR_STATUS: 'Aadhar Status',
    LABEL_LICENSE_STATUS: 'License Status',

    /* Commission Settings */
    LABEL_COMMISSION_AMOUNT: 'Commission Amount',
    LABEL_INITIAL_AMOUNT: 'Initial Amount',
    LABEL_INITIAL_DISTANCE: 'Initial Distanace',
    LABEL_REGULAR_FARE: 'Regular Fare',

    /*Coupon Page*/
    LABEL_ADD_NOTICES: 'Add Coupon',
    LABEL_EDIT_NOTICES: 'Edit Coupon',

    //-- buttons
    BTN_ADD: 'Add',
    BTN_SAVE: 'Save',
    BTN_DELETE: 'Delete',
    BTN_CANCEL: 'Cancel',
    BTN_BACK: 'Back',
    BTN_SENT: 'Sent',
    BTN_COPY: 'Copy',

    //-- Menus
    MENU_LOGOUT: 'Logout',
    MENU_DASHBOARD: 'Dashboard',
    MENU_USER_PROFILE: 'User Profile',
    MENU_MEMBERSHIP_MGT: 'Membership Management',
    MENU_USER_LIST: 'Users',
    MENU_COMMISSION_SETTING: 'Commission Setting',
    MENU_DOCUMENT_VERIFICATION: 'Document Verification',
    MENU_COUPON: 'Coupon',
    MENU_SHOPKEEPER: 'Shopkeepers',
    MENU_CATEGORY: 'Categories',
    MENU_PRODUCTS: 'Products',
    MENU_DISCOUNTS: 'Discounts',
    MENU_PROMOCODE: 'Promo Codes',
    MENU_REPORTS: 'Reports',
    MENU_USER_REPORT: 'User Report',
    MENU_REVENUE: 'Revenue',
    MENU_RIDE: 'Ride',
    MENU_TAX_SETTING: 'Tax Settings',
    MENU_TAGS: 'Tags',
    MENU_SETTINGS: 'Settings',
    MENU_GENERAL_SETTINGS: 'General',
    MENU_PAYMENT_TYPE: 'Payment Types',
    MENU_ORDERS: 'Orders',
    MENU_NOTIFICATIONS: 'Notifications',
    MENU_EMAIL_NOTIFICATIONS: 'Email Notifications',
    MENU_PUSH_NOTIFICATIONS: 'Push Notifications',
    MENU_PRE_MESSAGES: 'Predefined Messages',


    //-- General messages
    GEN_USERS_DESCRIPTION: 'This is user list',
    GEN_DISPLAY_DESCRIPTION: 'You can manage everything from this dashboard.',
    MSG_LOGOUT_WINDOW_READY: 'Ready to Leave?',
    MSG_LOGOUT_WINDOW_DESCRIPTION: 'Select "Logout" below if you are ready to end your current session.',
    GEN_DOCUMENT_VERIFICATION: 'This is list of pending user',
    MENU_TAX_SETTING_DESCRIPTION: 'Configure tax settings here',
    MENU_DISCOUNTS_DESCRIPTION: 'Configure discounts from here.',
    MENU_SETTINGS_DESCRIPTION: 'Configure discounts from hereConfigure discounts from here.',

    GENERAL_SETTINGS_DESC: 'View and update your store details',
    NOTIFICATIONS_DESC: 'These notifications are automatically sent out to the customer. Click on the notification template to edit the content.',


    //-- Add shopkeeper form
    LABEL_USER_SECTION_HEAD: 'PERSONAL INFO',
    LABEL_FIRST_NAME: 'First Name',
    LABEL_LAST_NAME: 'Last Name',
    LABEL_DOB: 'DOB',
    LABEL_LOCATION_SECTION: 'LOCATION INFO',
    LABEL_STREET: 'Street',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_COUNTRY: 'Country',
    LABEL_ZIP: 'Zip Code',
    LABEL_SHOP_TIMING : 'Shop Timing',
    LABEL_MONDAY : 'Monday',
    LABEL_TUESDAY : 'Tuesday',
    LABEL_WEDNESDAY : 'Wednesday',
    LABEL_THURSDAY : 'Thursday',
    LABEL_FRIDAY : 'Friday',
    LABEL_SATURDAY : 'Saturday',
    LABEL_SUNDAY : 'Sunday',


    LABEL_SHOP_SECTION_HEAD: 'SHOP INFO',
    LABEL_SHOP_TITLE: 'Title',
    LABEL_SHOP_CONTACT: 'Contact Number',
    LABEL_SHOP_LICENCE: 'Licence Number',
    LABEL_SHOP_LOGO: 'Shop Logo',
    LABEL_SHOP_NEW_PASSWORD_IF_REQUIRED: 'Insert new password if required.',
    LABEL_SHOP_NEW_IMAGE_IF_REQUIRED: 'Insert new image if required.',

    ERR_CONTACT_NOT_VALID: 'Contact number is not valid',
    CROP_IMAGE: 'Crop image',
    DONE_CROPPING: 'Done Cropping',
    CANCEL_CROPPING: 'Cancel',
    UPLOADING_IMAGE: 'Uploading image',
    LABLE_CREATE_SHOP: 'Shop Keeper with shop created successfully, redirecting in a moment!!',
    LABLE_UPDATE_SHOP: 'Shop Keeper with shop updated successfully, redirecting in a moment!!',
    LABEL_SHOP_FULL_INFO: 'Shop Location',
    LABEL_SHOPKEEPR_FULL_ADDRESS: 'SHOPKEEPER FULL ADDRESS',


    // SHOP KEEPER LIST PAGE TABEL LABELS
    GEN_FIRST_NAME: 'First Name',
    GEN_LAST_NAME: 'Last Name',
    GEN_EMAIL_ADDRESS: 'Email',
    GEN_DOB: 'DOB',
    GEN_MOBILE_NUMBER: 'Mobile',
    GEN_ACCOUNT_STATUS: 'Status',
    GEN_SHOP_TITLE: 'Shop',
    GEN_NO_RESULT: 'No Result',
    GEN_LOGO: 'Logo',

    /* Category page */
    LABEL_CATEGORY_POPUP: 'Add New Category',
    LABEL_CATEGORY_EDIT_POPUP: 'Edit Category',
    LABEL_SELECT_PARENT_CATEGORY: 'Select Parent Category',
    LABEL_CATEGORY_IMAGE: 'Category Logo',
    LABEL_CATEGORY_FIELD: 'Category Title',
    LABEL_CATEGORY_STATUS: 'Category Status',
    LABEL_CANCEL: 'Cancel',
    LABEL_SAVE: 'Save',

    GEN_CATEGORY_TITLE: 'Category Title',
    GEN_CATEGORY_PARENT_TITLE: 'Parent Category',
    GEN_CATEGORY_LOGO: 'Logo',
    GEN_CATEGORY_STATUS: 'Status',

    LABEL_ADD_PROMO_POPUP: 'Add New PromoCode',
    LABEL_PROMO_TITLE: 'Promo Code Title',
    LABEL_PROMO_TYPE: 'Promo Code Type',
    LABEL_PROMO_VALUE: 'Promo Code Value',
    LABEL_PROMO_LIMIT: 'Coupon Apply Limit For User',

    //-- Tag
    LABEL_TAGS: 'Tags',
    LABEL_ADD_TAGS: 'Add Tag',
    LABEL_EDIT_TAGS: 'Edit Tag',
    LABEL_NEW_TAG_FIELD: 'Tag Title',
    LABEL_TAG_TITLE_REQUIRED: 'Title Required',
    GEN_TAG_ADDED_NOTIFICATION: 'Tag added successfully.',
    GEN_ARE_SURE_TO_DELETE_TAG: "Are you sure!!, you want to delete this tag ?",
    GEN_TAG_DELETED_NOTIFICATION: 'Tag deleted successfully.',

    //-- Order
    LABEL_CURRENT_ORDER: 'Current Orders',
    LABEL_ORDER: 'Order',
    LABEL_ORDER_DATE : 'Order Date',
    LABEL_SHIPPING_AT : 'SHIPPING @',
    LABEL_CUSTOMER: 'Customer',
    LABEL_ORDER_TOTAL: 'Total',
    LABEL_ORDER_PAYMENT: 'Order',
    LABEL_ORDER_FULFILLMENT: 'Fulfillment',
    LABEL_ORDER_ITEMS: 'Items',
    LABEL_ORDER_ITEMS_CAPS: 'ITEMS',
    LABEL_PAYMENT: 'Payment',
    LABEL_PAYMENT_PENDING: 'Pending',
    LABEL_PAYMENT_PAID: 'Paid',
    LABEL_MARK_FULLFILLED: 'Mark as Fullfilled',
    LABEL_MARK_PAID: 'Mark as Paid',
    LABEL_NOTES: 'Notes',

    // product page
    LABEL_ADD_PRODUCT_POPUP: 'Add New Product',
    LABEL_PRODUCT_TITLE: 'Title (*)',
    LABEL_PRODUCT_TITLE_PLACEHOLDER: 'Title',
    LABEL_PRODUCT_DESCRIPTION: 'Description (*)',
    LABEL_PRODUCT_DESCRIPTION_PLACEHOLDER: 'Description',
    LABEL_PRODUCT_REGULAR_PRICE: 'Regular Price (*)',
    LABEL_PRODUCT_REGULAR_PRICE_PLACEHOLDER: 'Regular Price',
    LABEL_PRODUCT_SALE_PRICE: 'Sale Price',
    LABEL_PRODUCT_TAGS: 'Tags',
    LABEL_PRODUCT_BARCODE: 'Barcode',
    LABEL_PRODUCT_COST_PER_ITEM: 'Cost Per Item',
    LABEL_PRODUCT_COST_PER_ITEM_LABEL: 'Cost Per Item',
    LABEL_PRODUCT_MAIN_IMAGE: 'Main Image',
    LABEL_PRODUCT_IMAGE_GALLERY: 'Image Gallery (Select multiple image)',
    LABEL_PRODUCT_CATEGORY: 'Category',
    LABEL_PRODUCT_COUPON: 'COUPON(optional)',
    LABEL_PRODUCT_STOCK: 'Stock QTY (*)',
    LABEL_PRODUCT_STOCK_PLACEHOLDER: 'Stock QTY',
    LABEL_DATE: 'Date',
    LABEL_CREATED_DATE: 'Created Date',
    LABEL_UPDATED_DATE: 'Updated ON',
    LABEL_PRODUCT_SOLD_INDIVIDUALLY: 'Sale Individually',
    LABEL_PRODUCT_SKU: 'SKU Code (* Stock Keeping Unit)',
    LABEL_PRODUCT_SKU_PLACEHOLDER: 'SKU Code',
    LABEL_SAVING_PRODUCT_DATA: 'Uploading product data',

    LABEL_EDIT_PRODUCT_POPUP: 'Edit Product',

    GEN_PRICEING: 'Pricing',
    GEN_PRICE: 'Price',
    LABEL_PRODUCT_QUANTITY: 'Inventory and Quantity',
    LABEL_COST_PER_ITEM: 'Cost per item',
    LABEL_AVAILABLE: 'Available',
    GEN_PRODUCT_TITLE: 'Product Title',
    GEN_PRODUCT_REGULAR_PRICE: 'Regular Price',
    GEN_PRODUCT_SALE_PRICE: 'Sale Price',
    GEN_PRODUCT_CATEGORY: 'Category',
    GEN_SUB_CATEGORY: 'Sub Category',
    GEN_PRODUCT_STOCK: 'Stock QTY',
    GEN_PRODUCT_SKU: 'SKU',
    GEN_PRODUCT_BARCODE: 'Barcode',
    GEN_PRODUCT_ADDED_DATE: 'Added AT',
    GEN_ARE_SURE_TO_DELETE: "Are you sure!!, you want to delete this product ?",
    GEN_PRODUCT_DETAILS: "Product Details",
    GEN_DELETE_THIS: 'Delete',
    LABEL_SHOPLIST: 'Shop List',
    LABEL_SHOPLIST_DISCRPTION: 'Select shop to view products',
    LABEL_SHOP_PRODUCTS: 'Product list for shop : ',
    LABEL_PRODUCT_IMAGE_GALLERY_PLACEHOLDER: 'Image Gallery',
    LABEL_PRODUCT_COUPON_PLACEHOLDER: 'COUPON',
    LABEL_MARGIN_PER_ITEM: 'Margin Per Item',
    LABEL_PERC_MARGIN_PER_ITEM: '% MARGIN',
    LABEL_TAX : 'Tax',
    LABEL_POPUP_TAX_ADD: 'Add Tax Settings',
    LABEL_POPUP_TAX_UPDATE: 'Update Tax Settings',
    LABEL_SURE_DELTE_TAX_RECORD: 'Are you sure, you want to delete this record?',
    GEN_TAX_DELETED: 'Tax record deleted successfully.',
    LABEL_TAX_COUNTRY: 'Country Name(*)',
    LABEL_TAX_COUNTRY_CODE: 'Country Code(*)',
    LABEL_TAX_TYPE: 'Tax Type(*)',
    LABEL_TAX_VALUE: 'Tax Value(*)',
    LABEL_TAX_STATUS: 'Tax Status(*)',

    LABEL_TAX_COUNTRY_PLACEHOLDER: 'Country Name',
    LABEL_TAX_COUNTRY_CODE_PLACEHOLDER: 'Country Code ex: USA, GB, IN',
    LABEL_TAX_COUNTRY_CODE_PLACEHOLDER_RQR: 'Country Code',
    LABEL_TAX_TYPE_PLACEHOLDER: 'Tax Type',
    LABEL_TAX_VALUE_PLACEHOLDER: 'Tax Value',
    LABEL_TAX_STATUS_PLACEHOLDER: 'Tax Status',

    LABEL_ADD_DISCOUNT_POP_TITLE: 'Add Discount',
    LABEL_DISCOUNT_TITLE: 'Discount Title',
    LABEL_DISCOUNT_DESCRIPTION: 'Description',
    LABEL_DISCOUNT_TYPE: 'Type',
    LABEL_DISCOUNT_VALUE: 'Value',
    LABEL_DISCOUNT_STATUS: 'Status',
    LABEL_DISCOUNT_EXPIRY: 'Expiry',
    LABEL_DISCOUNT_CREATED_DATE: 'Created Date',
    GEN_DISCOUNT_ADDED_NOTIFICATION: 'Discount added successfully',
    GEN_DISCOUNT_UPDATED_NOTIFICATION: 'Discount updated successfully',
    LABEL_SURE_DELETE_DISCOUNT_RECORD: 'Are you sure, you want to delete this record?',
    GEN_DISCOUNT_DELETE_NOTIFICATION: 'Record deleted successfully',

    /*
    *  Settings
    */
    LABEL_ADMIN_CONTACT: 'Contact Person Name',
    LABEL_ADMIN_EMAIL: 'Admin Email',
    LABEL_SUPPORT_EMAIL1: 'Support Email 1',
    LABEL_SUPPORT_EMAIL2: 'Support Email 2',
    LABEL_OFFICE_ADDRESS: 'Office Address',
    LABEL_OFFICE_CITY: 'Office City',
    LABEL_OFFICE_COUNTRY: 'Office Country',
    LABEL_CONTACT1: 'Contact 1',
    LABEL_CONTACT2: 'Contact 2',

    //-- Notifications
    PUSH: 'Push',
    GEN_SENT: 'Sent',
    GEN_MESSAGE_FOR: 'Message For',
    NOTIFICATION_FOR: 'Notification For',


    GEN_BANNERS: 'Banners',
    LABEL_ADD_BANNERS: 'Add Banners',
    MENU_BANNERS: 'Banners',
    LABEL_BANNER_IMAGE: 'Banner Image',
    LABEL_POPUP_LABLE: 'Address',
    LABEL_EDIT_DEFAULT_ADDRESS: 'Select Default Address',
    GEN_ADDRESS_SET_SUCCESFULLY: 'Default address changed',
    EXTRA_ATTRIBUTES: 'Product Extra Attributes',
    LABEL_MORE_NOTIFICATIONS: 'More Notifications',
    LABEL_MY_NOTIFICATIONS: 'My Notifications',

    //orderDetails

    GEN_SUBTOTAL : 'Subtotal',
    GEN_ITEM : 'Item',
    GEN_DISCOUNT : 'Discount',
    GEN_COUPON :  'Coupon',
    GEN_ORDER_CODE : 'Order #',
    ACCOUNT_VERIFIED : 'Email verified successfully.',
    LINK_EXPIRED : 'The link you are trying to acsess is not valid or expired.',
    LABEL_MINIMUM_VALUE : 'Minimum Product Value',
    LABEL_LINK_STRIPE : 'Link Stripe Account',
    DESCRIPTION_LINK_STRIPE : 'Link your stripe account with us to receive payments in your stripe accounts on each order deliver through your shop.',

    //order defferent statusArray
    ORDER_TOTAL_PAID : 'Total Paid',
    ORDER_SHOP_ACEPTED_AT : 'Shop Accepted',
    ORDER_DELIVERY_BOY_ACCEPTED_AT : 'Delivery Boy Accepted',
    ORDER_PICKED_BOY_ACCEPTED_AT : 'Delivery Boy Picked',
    ORDER_DELIVERY_BOY_DELIVERED_AT : 'Order Delivered',
    ORDER_COMPLETE_AT : 'Order Completed',
    ORDER_SHOP_PREAPRED_AT : 'Shop Prepared',
    ORDER_SERVICE_CHARGE : 'Service Charge',
    ORDER_HITORY : 'Order History',
    CUSTOMER_ORDER_NOTES: 'Customer notes for delivery boy',
    CUSTOMER_PRODUCT_NOTES: 'Customer Note',
    ORDER_PENDING_AT_SHOP : 'Waiting for shop to accept',
    ORDER_IS_PREPARING_AT_SHOP: 'Order is prepairing at shop',
    ORDER_REJECTED_BY_SHOP : 'Order rejected by shop',
    ORDER_WAITING_FOR_DELIVERY_BOY_TO_ACCEPT : 'Waiting for a delivery boy to accept',
    ORDER_ACCEPTED_BY_DELIVERY_BOY : 'Order accepted by a delivery boy',
    ORDER_READY_TO_PICKUP : 'Order ready to pickup',
    ORDER_PICKED_BY_DELIVERY_BOY : 'Order picked by delivery boy',
    ORDER_DELIVERED : 'Order delivered',
    ORDER_COMPLETE : 'Order successfully completed',
    ORDER_AT : 'Order At',
    ORDER_TOTAL_SPENT : 'Total spent to date',
    LAST_ORDER : 'Last Order',
    AVERAGE_ORDER_VALUE : 'Average order value',
    ORDER_PLACED : 'Orders Placed',

    GEN_VIEW_ALL_ORDER : 'View all orders',
    GEN_EARNINGS_MONTHLY : 'Earnings (Monthly)',
    EXTRA_ADD_ONS : 'Extra add-ons',
    LABEL_COD_CHARGE : 'Cash on delivery charge',
    LABEL_COD_CHARGE_ORDER : 'Cod Charge',
    SEARCH_ORDER_ID : 'Search with order ID',
    LABEL_UTENSILS : 'Utensils',
    GEN_EARNINGS: 'Earnings',
    GEN_COD_EARNINGS: 'COD Earnings',
    GEN_OTHER_EARNINGS: 'Other Earnings',
    GEN_SHOP_EARNINGS : 'Shop Earnings',
    DATE_BASED_FILTERING : 'Filter order by Date'
};
