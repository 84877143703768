export const feedbackConstants = {

    ADD_CONTACT_DETAILS_REQUEST: 'ADD_CONTACT_DETAILS_REQUEST',
    ADD_CONTACT_DETAILS_SUCCESS: 'ADD_CONTACT_DETAILS_SUCCESS',
    ADD_CONTACT_DETAILS_FAILURE: 'ADD_CONTACT_DETAILS_FAILURE',

    ADD_NEWSLETTER_REQUEST: 'ADD_NEWSLETTER_REQUEST',
    ADD_NEWSLETTER_SUCCESS: 'ADD_NEWSLETTER_SUCCESS',
    ADD_NEWSLETTER_FAILURE: 'ADD_NEWSLETTER_FAILURE'
    
}