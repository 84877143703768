import { inventoryConstants } from '../constants';

export function model(state = {}, action) {

  switch (action.type) {
    case inventoryConstants.CATEGORY_GET_ACTIVE_SUCCESS:
      return { modelList:action.successAction.data};

    default:

        return state;

  }
}
