import { inventoryConstants } from '../constants';

export function make(state = {}, action) {

  switch (action.type) {
    case inventoryConstants.MAKE_GET_ACTIVE_SUCCESS:
      return { makeList:action.successAction.data};

    default:

        return state;

  }
}
