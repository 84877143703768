import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap';
import {history} from '../../../helpers';
import Menu from './Menu';

const HeaderInner = () => {

    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const token = sessionStorage.getItem('token');
    const userName = sessionStorage.getItem('userName');

    // const demoMessage = () =>{
    //     let chk =window.confirm("Use below credentials for Demo.\n\n User- demo@dtkcrm.ca \n Password- demo123");
    //     if(chk){
    //         window.location.href = "http://dtkcrm.ca:8080";
    //     }
    // }

    const logout = () => {
        if(token!==undefined && userName!==undefined){
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userName');
            history.push('/login');
        }
    }

    useEffect(()=>{
        if(token!==undefined && token!==null){
            setIsLoggedIn(true);
        }
    },[])

    return (
        <>
        <div class="preloader">
            <div class="meter">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="subline"></div>
                <div class="subline"></div>
                <div class="subline"></div>
                <div class="subline"></div>
                <div class="subline"></div>
                <div class="loader-circle-1">
                    <div class="loader-circle-2"></div>
                </div>
                <span class="loadtext">Loading</span>
            </div>
        </div>
        
        <header class="header-style-two inner-page-header">
            <div class="header-top-wrap">
                <div class="container custom-container">
                    <div class="row">
                        <div class="col-md-6 d-none d-md-block">
                            <div class="header-top-action">
                                <ul>
                                    <li><i class="far fa-envelope"></i><a href="mailto:davidlingam@hotmail.com">davidlingam@hotmail.com</a></li>
                                    <li><i class="fas fa-phone-alt"></i><a href="mailto:davidlingam@hotmail.com">davidlingam@hotmail.com</a></li>
                                    <li><i class="far fa-clock"></i> 10:00 AM To 5:00 PM</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="header-top-right">
                                <ul>
                                    <li class="header-top-social">
                                        <a href="#"><i class="fab fa-facebook-square"></i></a>
                                        <a href="#"><i class="fab fa-twitter-square"></i></a>
                                        <a href="#"><i class="fab fa-vimeo-square"></i></a>
                                        <a href="#"><i class="fab fa-linkedin"></i></a>
                                    </li>
                                    {/* <li class="header-top-user">
                                        <div class="top-user-thumb">
                                            <img src="assets/img/images/header_top_user.jpg" alt="" /> 
                                        </div>
                                        <a href="author-profile.html">Alexandra</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Menu />
        </header>
        </>
    )
}

export default HeaderInner;