import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FooterBrandArea from '../Common/Footer/FooterBrandArea';
import CarByCategory from '../Common/CarByCategory';
import { inventoryAction } from '../../actions';
import { apiConstants } from '../../constants';

const Home = (history) => {
    // const dispatch = useDispatch();
    const [siteConstants, setSiteConstants] = useState([])
    const inventoryList = useSelector((state) => state.inventory);
    const [inventory, setInventory] = useState([])
    const [formData, setFormData] = useState({
        vin: '', fuel_type: '', make: '', model: '', year: '', price: '', car_type: '', transmission: ''
    });
    const [make, setMake] = useState([])
    const [model, setModel] = useState([]) //-- category

    const modelList = useSelector((state) => state.model);
    const makeList = useSelector((state) => state.make);

    const { language } = useSelector(
        state => ({
            language: state.language
        })
    );

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }
    }, [language])

    const dispatch = useDispatch();
    useEffect(() => {
        var obj = {
            pagging: 6
        }
        dispatch(inventoryAction.getInventoryList(apiConstants.GET_PRODUCT_LIST, obj));
        dispatch(inventoryAction.getAllActiveCategory(apiConstants.GET_ALL_ACTIVE_CATEGORIES));

        dispatch(inventoryAction.getAllActiveMake(apiConstants.GET_ALL_ACTIVE_MAKES));

    }, [])


    useEffect(() => {
        if (Object.keys(inventoryList).length > 0) {
            setInventory(inventoryList.inventoryList);
        }
    }, [inventoryList])

    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onChangeHandlerDDL = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (Object.keys(modelList).length > 0) {
            setModel(modelList.modelList.data);
        }
    }, [modelList])

    useEffect(() => {
        if (Object.keys(makeList).length > 0) {
            setMake(makeList?.makeList?.data);
        }
    }, [makeList])

    // const [modalShow, setModalShow] = React.useState(false);

    const [mission, setMission] = useState([
        { 'name': 'Business Marketing', 'description': 'You can add as many clients as you wish to build your business.', 'img': '/assets/images/increase.png', 'link': '' },
        { 'name': 'Security', 'description': 'Your clients are only yours. This site is fully secured.', 'img': '/assets/images/agreement.png', 'link': '' },
        { 'name': 'Close Deals Faster', 'description': 'Whether you’re on a mobile device or on a desktop computer in the showroom, DTK CRM works everywhere', 'img': '/assets/images/help.png', 'link': '' },
        { 'name': 'Save Money', 'description': 'It is priced affordably for even the smallest dealership and more cost-efficient than the competition', 'img': '/assets/images/money.png', 'link': '' }
    ]);

    const [fundRaising, setFundRaising] = useState([
        {
            'name': '', 'description': 'Virtual Auction'
            , 'img': '/assets/images/virtualau.png'
        },
        {
            'name': '', 'description': 'CarHunt',
            'img': '/assets/images/carhunt.jpg'
        },
        {
            'name': '', 'description': 'Virtual Auction'
            , 'img': '/assets/images/virtualau.png'
        },
        {
            'name': '', 'description': 'CarHunt',
            'img': '/assets/images/carhunt.jpg'
        }
    ]);

    const [ourProjects, setOurProjects] = useState([
        {
            'progress': '75', 'description': 'David Lingam', 'raised': '$18,120,00', 'goal': '$25,120,00',
            'img': '/assets/images/team1.jpeg'
        },
        {
            'progress': '75', 'description': 'Damian R', 'raised': '$18,120,00', 'goal': '$25,120,00',
            'img': '/assets/images/logo4.png'
        },
        {
            'progress': '75', 'description': 'Tayden R', 'raised': '$18,120,00', 'goal': '$25,120,00',
            'img': '/assets/images/logo4.png'
        },
        {
            'progress': '75', 'description': 'Kailan R', 'raised': '$18,120,00', 'goal': '$25,120,00',
            'img': '/assets/images/logo4.png'
        },
        {
            'progress': '75', 'description': 'Judy Lingam', 'raised': '$18,120,00', 'goal': '$25,120,00',
            'img': '/assets/images/logo4.png'
        },

    ]);

    const bannerSlider = [
        { 'img': '/assets/images/car1.png', 'name': 'Let’s Change World For Better Future', 'description': 'Crowdfunding, charity, nonprofit, NGO, donations and all other businesses' },
        { 'img': '/assets/images/car2.png', 'name': 'Let’s Change World For Better Future', 'description': 'Crowdfunding, charity, nonprofit, NGO, donations and all other businesses' },
        { 'img': '/assets/images/car3.jpg', 'name': 'Let’s Change World For Better Future', 'description': 'Crowdfunding, charity, nonprofit, NGO, donations and all other businesses' },
        { 'img': '/assets/images/car4.jpeg', 'name': 'Let’s Change World For Better Future', 'description': 'Crowdfunding, charity, nonprofit, NGO, donations and all other businesses' },
        { 'img': '/assets/images/car5.jpg', 'name': 'Let’s Change World For Better Future', 'description': 'Crowdfunding, charity, nonprofit, NGO, donations and all other businesses' }
    ]

    const onSubmit = (e) => {
        e.preventDefault();
        
        // window.location.href("/home")
        //dispatch(inventoryAction.getInventoryList(apiConstants.GET_PRODUCT_LIST, formData));

    }

    const featuredCategory = [
        { 'name': 'Marketing Strategy', 'img': '/assets/images/target.png' },
        { 'name': 'Project Branding', 'img': '/assets/images/certified.png' },
        { 'name': 'Setup a Dealers', 'img': '/assets/images/gear.png' },
        { 'name': 'Increase Brand Values', 'img': '/assets/images/money-w.png' },
    ]

    return (
        <>
            <main>

                <section class="slider-area">
                    <div class="slider-active">
                        <div class="single-slider">
                            {/* <div class="slider-angle-shape" data-background="assets/img/slider/slider_shape.png"></div> */}
                            <div class="slider-bg" data-background="assets/img/slider/slider1.jpg"></div>
                            <div class="container custom-container">
                                <div class="row">
                                    <div class="col-xl-5 col-lg-6">
                                        <div class="slider-content">
                                            <span data-animation="fadeInUp" data-delay=".3s">best auto site</span>
                                            <h2 data-animation="fadeInUp" data-delay=".6s">Professional Service Provider</h2>
                                            <p data-animation="fadeInUp" data-delay=".9s">Find the Perfect Vehicle With Our Car Finder Tool</p>
                                            <a href="/inventory" class="btn" data-animation="fadeInUp" data-delay="1.2s"><span>+</span> discover more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="single-slider">
                            <div class="slider-angle-shape" data-background="assets/img/slider/slider_shape.png"></div>
                            <div class="slider-bg" data-background="assets/img/slider/slider1.jpg"></div>
                            <div class="container custom-container">
                                <div class="row">
                                    <div class="col-xl-5 col-lg-6">
                                        <div class="slider-content">
                                            <span data-animation="fadeInUp" data-delay=".3s">best auto site</span>
                                            <h2 data-animation="fadeInUp" data-delay=".6s">Professional Service Provider</h2>
                                            <p data-animation="fadeInUp" data-delay=".9s">Find the Perfect Vehicle With Our Car Finder Tool</p>
                                            <a href="/inventory" class="btn" data-animation="fadeInUp" data-delay="1.2s"><span>+</span> discover more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="car-search-area gray-bg">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="car-search-wrap">
                                    <div class="small-title mb-20">
                                        <h4><i class="flaticon-searching-car"></i> LOOKING FOR A <span>VEHICLE?</span></h4>
                                    </div>
                                    <form action="#" class="car-search-form">
                                        <div class="row align-items-end">
                                            <div class="col custom-col-5">
                                                <label>MAKE</label>
                                                <select name="make" id="make" onChange={(e) => onChangeHandlerDDL(e)} style={{
                                                    "width": "100%",
                                                    "display": "block",
                                                    "background": "#f3f3f3",
                                                    "borderRadius": 0,
                                                    "padding": "12px 40px 12px 20px",
                                                    "color": "#73757a",
                                                    "fontWeight": "400", "border": "1px solid #e1e1e1"
                                                }}>
                                                    <option value="">Select Make</option>
                                                    {

                                                        make?.map((cat, index) => {
                                                            return <option value={cat.makeTitle}>{cat.makeTitle}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="col custom-col-5">
                                                <label>MODEL</label>
                                                <select name="model" onChange={(e) => onChangeHandlerDDL(e)} style={{
                                                    "width": "100%",
                                                    "display": "block",
                                                    "background": "#f3f3f3",
                                                    "borderRadius": 0,
                                                    "padding": "12px 40px 12px 20px",
                                                    "color": "#73757a",
                                                    "fontWeight": "400", "border": "1px solid #e1e1e1"
                                                }}>
                                                    <option value="">Select Model</option>
                                                    {
                                                        model.map((cat, index) => {
                                                            return <option value={cat.categoryTitle}>{cat.categoryTitle}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div class="col custom-col-5">
                                                <label>Vehicle Status</label>
                                                <select name="name" style={{
                                                    "width": "100%",
                                                    "display": "block",
                                                    "background": "#f3f3f3",
                                                    "borderRadius": 0,
                                                    "padding": "12px 40px 12px 20px",
                                                    "color": "#73757a",
                                                    "fontWeight": "400", "border": "1px solid #e1e1e1"
                                                }}>
                                                    <option value="">Select Status</option>
                                                    <option value="new">New</option>
                                                    <option value="used">Used</option>
                                                </select>
                                            </div>
                                            <div class="col custom-col-5">
                                                <label>Model Year</label>
                                                <input type="text" name="year" placeholder="2024" onChange={(e) => onChangeHandler(e)} style={{
                                                    "width": "100%",
                                                    "display": "block",
                                                    "background": "#f3f3f3",
                                                    "border-radius": 0,
                                                    "padding": "12px 40px 12px 20px",
                                                    "color": "#73757a",
                                                    "fontWeight": "400", "border": "1px solid #e1e1e1"
                                                }} />
                                            </div>
                                            <div class="col custom-col-5">
                                                {/* <button class="btn" onClick={(e) => onSubmit(e)}>SELECT VEHICLE</button> */}
                                                <a href="/inventory" class="btn">SELECT VEHICLE</a>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="car-search-map-icon"><i class="flaticon-location"></i></div>
                                    <div class="car-search-shape"><img src="assets/img/images/car_search_shape.png" alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="popular-selling-brand gray-bg pt-50 pb-20">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="popular-selling-wrap">
                                    <div class="popular-selling-top">
                                        <div class="popular-selling-title">
                                            <h4>popular selling VEHICLES</h4>
                                        </div>
                                        <div class="see-all-brand">
                                            {/* <a href="#">See all Makes</a> */}
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/BMW.png" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/BUICK.png" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/CHEVROLET.jpeg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/FORD.png" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/gmc.png" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/HYUNDAI.jpeg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/KIA.jpeg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/MERCEDES.jpeg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/NISSAN.jpeg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand01.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand02.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand03.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand04.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand05.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand06.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand07.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-4 col-sm-6">
                                            <div class="popular-selling-items">
                                                <a href="#"><img src="assets/img/brand/popular_brand08.jpg" alt="" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="popular-selling-ad-banner">
                                    <a href="#"><img src="assets/img/carhunt/2022_chevrolet_equinox_4dr-suv_premier_fq_oem_1_8153.jpg" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="latest-cars-area pt-20 pb-120">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-5 col-lg-6">
                                <div class="section-title text-center mb-35">
                                    <h2 class="overlay-title">All Seller</h2>
                                    <span class="sub-title">our Featured Cars</span>
                                    <h2 class="title">latest released Cars</h2>
                                </div>
                                {/* <div class="latest-car-menu-wrap">
                                    <div class="latest-car-menu-active">
                                        <button class="active" data-filter="*">all SELLER</button>
                                        <button class="" data-filter=".cat-one">NEW CARS</button>
                                        <button class="" data-filter=".cat-two">USED CARS</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class="latest-cars-wrapper">
                            <div class="row latest-car-items-active">
                                {
                                    // console.log("inventory ",inventory)
                                    (inventory.length > 0) ?
                                        inventory.map((prd, index) => {
                                            return <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-two">
                                                <div class="latest-car-item mb-60">
                                                    <div class="latest-car-thumb mb-25">
                                                        <a href={"/inventory-details/" + prd._id}>
                                                            <img src={prd.images} alt="" />
                                                        </a>
                                                    </div>
                                                    <div class="latest-car-content">
                                                        <div class="latest-car-content-top">
                                                            <h5><a href={"/inventory-details/" + prd._id}>{prd.car_type}</a></h5>
                                                            <span class="price">{(prd.price > 0) ? '$' + prd.price : 'NA'}</span>
                                                        </div>
                                                        <p>Build Year : <span>{prd.year}</span></p>
                                                        <div class="latest-car-meta">
                                                            <ul>
                                                                <li><i class="flaticon-settings"></i> {prd.transmission}</li>
                                                                <li><i class="flaticon-gasoline-pump"></i> {prd.fuel_type}</li>
                                                                <li><i class="flaticon-motorway"></i> {prd.kilometres} km</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                        : ''
                                }
                                {/* <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-two">
                                    <div class="latest-car-item mb-60">
                                        <div class="latest-car-thumb mb-25">
                                            <a href="inventory-details.html">
                                                <img src="assets/img/images/released_cars_thumb01.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div class="latest-car-content">
                                            <div class="latest-car-content-top">
                                                <h5><a href="inventory-details.html">Jaguer M1 Hybrid</a></h5>
                                                <span class="price">$2300</span>
                                            </div>
                                            <p>Build Year : <span>2020</span></p>
                                            <div class="latest-car-meta">
                                                <ul>
                                                    <li><i class="flaticon-settings"></i> Automatic</li>
                                                    <li><i class="flaticon-gasoline-pump"></i> Diesel</li>
                                                    <li><i class="flaticon-motorway"></i> 7500 km</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-one">
                                    <div class="latest-car-item mb-60">
                                        <div class="latest-car-thumb mb-25">
                                            <a href="inventory-details.html">
                                                <img src="assets/img/images/released_cars_thumb02.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div class="latest-car-content">
                                            <div class="latest-car-content-top">
                                                <h5><a href="inventory-details.html">Audi New BoM 800</a></h5>
                                                <span class="price">$2900</span>
                                            </div>
                                            <p>Build Year : <span>2020</span></p>
                                            <div class="latest-car-meta">
                                                <ul>
                                                    <li><i class="flaticon-settings"></i> Automatic</li>
                                                    <li><i class="flaticon-gasoline-pump"></i> Diesel</li>
                                                    <li><i class="flaticon-motorway"></i> 7500 km</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-two">
                                    <div class="latest-car-item mb-60">
                                        <div class="latest-car-thumb mb-25">
                                            <a href="inventory-details.html">
                                                <img src="assets/img/images/released_cars_thumb03.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div class="latest-car-content">
                                            <div class="latest-car-content-top">
                                                <h5><a href="inventory-details.html">Mercedez Benz Ei8</a></h5>
                                                <span class="price">$2100</span>
                                            </div>
                                            <p>Build Year : <span>2020</span></p>
                                            <div class="latest-car-meta">
                                                <ul>
                                                    <li><i class="flaticon-settings"></i> Automatic</li>
                                                    <li><i class="flaticon-gasoline-pump"></i> Diesel</li>
                                                    <li><i class="flaticon-motorway"></i> 7500 km</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-one cat-two">
                                    <div class="latest-car-item mb-60">
                                        <div class="latest-car-thumb mb-25">
                                            <a href="inventory-details.html">
                                                <img src="assets/img/images/released_cars_thumb04.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div class="latest-car-content">
                                            <div class="latest-car-content-top">
                                                <h5><a href="inventory-details.html">Audi A8 Hybrid</a></h5>
                                                <span class="price">$1900</span>
                                            </div>
                                            <p>Build Year : <span>2020</span></p>
                                            <div class="latest-car-meta">
                                                <ul>
                                                    <li><i class="flaticon-settings"></i> Automatic</li>
                                                    <li><i class="flaticon-gasoline-pump"></i> Diesel</li>
                                                    <li><i class="flaticon-motorway"></i> 7500 km</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-one">
                                    <div class="latest-car-item mb-60">
                                        <div class="latest-car-thumb mb-25">
                                            <a href="inventory-details.html">
                                                <img src="assets/img/images/released_cars_thumb05.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div class="latest-car-content">
                                            <div class="latest-car-content-top">
                                                <h5><a href="inventory-details.html">Audi New BoM 800</a></h5>
                                                <span class="price">$1700</span>
                                            </div>
                                            <p>Build Year : <span>2020</span></p>
                                            <div class="latest-car-meta">
                                                <ul>
                                                    <li><i class="flaticon-settings"></i> Automatic</li>
                                                    <li><i class="flaticon-gasoline-pump"></i> Diesel</li>
                                                    <li><i class="flaticon-motorway"></i> 7500 km</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 grid-item grid-sizer cat-one cat-two">
                                    <div class="latest-car-item mb-60">
                                        <div class="latest-car-thumb mb-25">
                                            <a href="inventory-details.html">
                                                <img src="assets/img/images/released_cars_thumb06.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div class="latest-car-content">
                                            <div class="latest-car-content-top">
                                                <h5><a href="inventory-details.html">Audi New BoM 800</a></h5>
                                                <span class="price">$1200</span>
                                            </div>
                                            <p>Build Year : <span>2020</span></p>
                                            <div class="latest-car-meta">
                                                <ul>
                                                    <li><i class="flaticon-settings"></i> Automatic</li>
                                                    <li><i class="flaticon-gasoline-pump"></i> Diesel</li>
                                                    <li><i class="flaticon-motorway"></i> 7500 km</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="latest-car-btn text-center mt-10">
                                        <a href="/inventory" class="btn">SHOW ALL CARS</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="fact-area fact-bg pt-120 pb-70 jarallax parallax-img" data-background="assets/img/carhunt/2025_Tahoe.jpg">
                    <div class="fact-area-title">Find your Perfect VEHICLE <span class="left"></span><span class="right"></span></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="fact-item mb-50">
                                    <div class="fact-icon">
                                        <i class="flaticon-car-3"></i>
                                    </div>
                                    <div class="fact-content">
                                        <h3><span class="odometer" data-count="2900"></span>+</h3>
                                        <p>NEW CARS IN STOCK</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="fact-item mb-50">
                                    <div class="fact-icon">
                                        <i class="flaticon-service-1"></i>
                                    </div>
                                    <div class="fact-content">
                                        <h3><span class="odometer" data-count="3400"></span>+</h3>
                                        <p>USED CARS IN STOCK</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="fact-item mb-50">
                                    <div class="fact-icon">
                                        <i class="flaticon-gears"></i>
                                    </div>
                                    <div class="fact-content">
                                        <h3><span class="odometer" data-count="1200"></span>+</h3>
                                        <p>SERVICE CENTERS</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="fact-item mb-50">
                                    <div class="fact-icon">
                                        <i class="flaticon-rocket"></i>
                                    </div>
                                    <div class="fact-content">
                                        <h3><span class="odometer" data-count="34"></span>K</h3>
                                        <p>HAPPY CLIENTS</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="exclusive-featured-area pt-120 pb-120">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-6 col-lg-7">
                                <div class="section-title text-center mb-70">
                                    <h2 class="overlay-title">Our Services</h2>
                                    <span class="sub-title">our service Cars</span>
                                    <h2 class="title">Exclusive Car Featured</h2>
                                    <div class="line"><img src="assets/img/images/title_line.png" alt="" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center align-items-xl-start">
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="exc-featured-item">
                                    <div class="icon">
                                        <i class="flaticon-belt"></i>
                                    </div>
                                    <div class="content">
                                        <h5>SUPER FAST car</h5>
                                        <p>Placlder text commonly used detrate theyjlos visual form amet, consectetur ading elitmod tempor used.</p>
                                    </div>
                                </div>
                                <div class="exc-featured-item">
                                    <div class="icon">
                                        <i class="flaticon-piston"></i>
                                    </div>
                                    <div class="content">
                                        <h5>dealership points</h5>
                                        <p>Placlder text commonly used detrate theyjlos visual form amet, consectetur ading elitmod tempor used.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-4 d-none d-lg-block">
                                <div class="exc-featured-img">
                                    <img src="assets/img/carhunt/hummer.jpg" alt="" />
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                <div class="exc-featured-item">
                                    <div class="icon">
                                        <i class="flaticon-fan"></i>
                                    </div>
                                    <div class="content">
                                        <h5>SUPER FAST car</h5>
                                        <p>Placlder text commonly used detrate theyjlos visual form amet, consectetur ading elitmod tempor used.</p>
                                    </div>
                                </div>
                                <div class="exc-featured-item">
                                    <div class="icon">
                                        <i class="flaticon-jerrycan"></i>
                                    </div>
                                    <div class="content">
                                        <h5>dealership points</h5>
                                        <p>Placlder text commonly used detrate theyjlos visual form amet, consectetur ading elitmod tempor used.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="team-area team-bg pt-120 pb-120" data-background="assets/img/bg/team_bg.jpg">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-6 col-lg-7">
                                <div class="section-title text-center white-title mb-70">
                                    <span class="sub-title">our expert team</span>
                                    <h2 class="title">Our Customer Advisor</h2>
                                    <div class="line"><img src="assets/img/images/title_line.png" alt="" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center align-items-xl-start">
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                
                            </div>
                            <div class="col-xl-6 col-lg-4 d-none d-lg-block" style={{textAlign:"-webkit-center"}}>
                            <div class="team-item" style={{height:"40%",width:"40%"}}>
                                    <div class="team-thumb">
                                        <img src="assets/img/carhunt/team/david.jpeg" alt="" />
                                    </div>
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="team-content">
                                        <h5>David Lingam</h5>
                                        <a href="tel:7783029099" class="phone">778-302-9099</a>
                                        <a href="mailto:emily@vehicatheme.com" class="mail">info@carhunt.ca</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-6">
                                
                            </div>
                        </div>
                        {/* <div class="row team-active">
                            <div class="col-xl-3">
                                <div class="team-item">
                                    <div class="team-thumb">
                                        <img src="assets/img/carhunt/team/david.jpeg" alt="" />
                                    </div>
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="team-content">
                                        <h5>dealership points</h5>
                                        <a href="tel:123456789" class="phone">(123) 345-6789</a>
                                        <a href="mailto:emily@vehicatheme.com" class="mail">emily@vehicatheme.com</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3">
                                <div class="team-item">
                                    <div class="team-thumb">
                                        <img src="assets/img/carhunt/team/david.jpeg" alt="" />
                                    </div>
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="team-content">
                                        <h5>lora williams</h5>
                                        <a href="tel:123456789" class="phone">(123) 345-6789</a>
                                        <a href="mailto:emily@vehicatheme.com" class="mail">emily@vehicatheme.com</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3">
                                <div class="team-item">
                                    <div class="team-thumb">
                                        <img src="assets/img/carhunt/team/david.jpeg" alt="" />
                                    </div>
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="team-content">
                                        <h5>gareth baleson</h5>
                                        <a href="tel:123456789" class="phone">(123) 345-6789</a>
                                        <a href="mailto:emily@vehicatheme.com" class="mail">emily@vehicatheme.com</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3">
                                <div class="team-item">
                                    <div class="team-thumb">
                                        <img src="assets/img/carhunt/team/david.jpeg" alt="" />
                                    </div>
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="team-content">
                                        <h5>dealership points</h5>
                                        <a href="tel:123456789" class="phone">(123) 345-6789</a>
                                        <a href="mailto:emily@vehicatheme.com" class="mail">emily@vehicatheme.com</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3">
                                <div class="team-item">
                                    <div class="team-thumb">
                                        <img src="assets/img/team/team_thumb02.jpg" alt="" />
                                    </div>
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                        </ul>
                                    </div>
                                    <div class="team-content">
                                        <h5>Tenosy Hong</h5>
                                        <a href="tel:123456789" class="phone">(123) 345-6789</a>
                                        <a href="mailto:emily@vehicatheme.com" class="mail">emily@vehicatheme.com</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
<br></br>
                {/* <section class="car-category-area pt-120 pb-120">
                    <CarByCategory />
                </section> */}

                {/* <section class="app-download-area pb-120">
                    <div class="container">
                        <div class="app-download-bg" data-background="assets/img/bg/app_download_bg.png">
                            <div class="row">
                                <div class="col-xl-6 col-lg-7">
                                    <div class="app-download-title">
                                        <span class="sub-title">download app</span>
                                        <h2 class="title">Download Our Free App Car Service</h2>
                                        <p>Placlder text commonly used detrate visual</p>
                                        <div class="download-btn">
                                            <a href="#" class="app-btn"><img src="assets/img/images/download_btn01.png" alt="" /></a>
                                            <a href="#" class="google-btn"><img src="assets/img/images/download_btn02.png" alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="app-screenshot-img">
                                <img src="assets/img/images/app_shot.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section> */}

                <FooterBrandArea />

            </main>
        </>
    )
}

export default Home;