import { inventoryConstants } from '../constants';

export function inventory(state = {}, action) {

  // console.log("RED ",action.successAction)
  switch (action.type) {
    case inventoryConstants.GET_INVENTORY_LIST_SUCCESS:
      return { inventoryList: action.successAction.data.allData };

    case inventoryConstants.SINGLE_PRODUCT_GET_SUCCESS:
      return { inventoryDetails: action.successAction.data.productAllDetail };

    default:

      return state;

  }
}
