import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from '../helpers';
import Home from "../components/Home/Home";
import MainLayout from "../components/Layout/MainLayout/MainLayout";
import InnerLayout from "../components/Layout/InnerLayout/InnerLayout";
import AboutUs from "../components/AboutUs/AboutUs";
import Contact from "../components/Contact/Contact";
import SellCar from "../components/SellCar/SellCar";
import Demo from "../components/Demo/Demo";
import Faq from '../components/Faq/Faq';
import LoanCalculator from '../components/LoanCalculator/LoanCalculator';
import Inventory from '../components/Inventory/Inventory';
import InventoryDetails from '../components/Inventory/InventoryDetails';
import './App.css';

const App = () => {

  return (
    <Router history={history}>
      <Switch >
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <MainLayout path="/home" component={Home} />
        <InnerLayout path="/about-us" component={AboutUs} />
        <InnerLayout path="/contact-us" component={Contact} />
        <InnerLayout path="/sell-car" component={SellCar} />
        {/* <InnerLayout path="/faq" component={Faq} /> */}
        <InnerLayout path="/demo" component={Demo} />
        <InnerLayout path="/loan-calculator" component={LoanCalculator} />
        <InnerLayout path="/inventory" component={Inventory} />
        <InnerLayout path="/inventory-details/:id" component={InventoryDetails} />
        {/* <InnerLayout>
        <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/contact-us" component={Contact} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/demo" component={Demo} />
        </InnerLayout> */}
      </Switch>
    </Router>
  );
}
export default App;