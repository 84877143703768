import { inventoryConstants } from "../constants";
import * as commonAction from "./commonAction";
import { commonService } from "../services";


export const inventoryAction = {
  getAllActiveMake,
  getAllActiveCategory,
  getInventoryList,
  getSingleProduct
};

//get all the active categories
//-- Add contact details function
function getAllActiveCategory(apiName,obj) {
  return dispatch => {
    dispatch(commonAction.request(inventoryConstants.CATEGORY_GET_ACTIVE_REQUEST));

    commonService.withOutToken(apiName,obj).then(
      user => {
        dispatch(commonAction.success(inventoryConstants.CATEGORY_GET_ACTIVE_SUCCESS, user));
        //dispatch(alertActions.success("Email Sent Successfully"));
        // setTimeout(function () {
        //   dispatch(alertActions.clear());
        // }, 3000);
      },
      error => {
        dispatch(commonAction.failure(inventoryConstants.CATEGORY_GET_ACTIVE_FAILURE, error));
        console.log("err is ==================",error)
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}

function getAllActiveMake(apiName,obj) {
  return dispatch => {
    dispatch(commonAction.request(inventoryConstants.MAKE_GET_ACTIVE_REQUEST));

    commonService.withOutToken(apiName,obj).then(
      user => {
        dispatch(commonAction.success(inventoryConstants.MAKE_GET_ACTIVE_SUCCESS, user));
        //dispatch(alertActions.success("Email Sent Successfully"));
        // setTimeout(function () {
        //   dispatch(alertActions.clear());
        // }, 3000);
      },
      error => {
        dispatch(commonAction.failure(inventoryConstants.MAKE_GET_ACTIVE_FAILURE, error));
        console.log("err is ==================",error)
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}


function getInventoryList(apiName,obj) {
  return dispatch => {
    dispatch(commonAction.request(inventoryConstants.GET_INVENTORY_LIST_REQUEST));

    commonService.withOutToken(apiName,obj).then(
      user => {
        dispatch(commonAction.success(inventoryConstants.GET_INVENTORY_LIST_SUCCESS, user));
      },
      error => {
        dispatch(commonAction.failure(inventoryConstants.GET_INVENTORY_LIST_FAILURE, error));
        console.log("err is ==================",error)
        // dispatch(alertActions.error(error.response.data.error));
      }
    );
  };
}


function getSingleProduct(apiName, reqObj) {
  return dispatch => {
    dispatch(commonAction.request(inventoryConstants.SINGLE_PRODUCT_GET_REQUEST, reqObj ));
    commonService.withToken(apiName, reqObj).then(
      getProduct => {        
        dispatch(commonAction.success(inventoryConstants.SINGLE_PRODUCT_GET_SUCCESS, getProduct));
      },
      error => {
        dispatch(
          commonAction.failure(inventoryConstants.SINGLE_PRODUCT_GET_FAILURE, error)
        );        
      }
      );
  };
}