// import React from 'react';
// // import DashboardLeftMenu from '../Common/DashboardLeftMenu/DashboardLeftMenu';
import HeaderInner from "../../Common/Header/HeaderInner";
import Footer from "../../Common/Footer/Footer";

// const InnerLayout = (props) => {
//     return (
//         <React.Fragment>
//             <HeaderInner />
//             {props.children}
//             <Footer />
//         </React.Fragment>
//     )
// }

// export default InnerLayout;

import React, { Component } from 'react';
import { Route } from 'react-router-dom';

const InnerLayoutData = ({ children, ...rest }) => {
    return (
        <div className="page page-dashboard">
            {/* <div className="sidebar">This is the Second Layout</div> */}
            <div className="main">{children}</div>
        </div>
    )
}

const InnerLayout = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={matchProps => (
            <InnerLayoutData>
                <HeaderInner />
                <Component {...matchProps} />
                <Footer />
            </InnerLayoutData>
        )} />
    )
};

export default InnerLayout;