import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import FooterBrandArea from '../Common/Footer/FooterBrandArea';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryAction } from '../../actions';
import { apiConstants } from '../../constants';
import InventoryRightBarSearch from '../Common/InventoryRightBarSearch';

const imageRootPath = process.env.REACT_APP_IMAGE_PATH + 'uploads/products/';

const Inventory = ({ history }) => {
  const [inventory, setInventory] = useState([])
  const inventoryList = useSelector((state) => state.inventory);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(inventoryAction.getInventoryList(apiConstants.GET_PRODUCT_LIST));

  }, [])

  useEffect(() => {
    if (Object.keys(inventoryList).length > 0) {
      setInventory(inventoryList.inventoryList);
    }
  }, [inventoryList])

  return (
    <main>

      {/* <!-- breadcrumb-area --> */}
      <section class="breadcrumb-area breadcrumb-bg" data-background="assets/img/bg/breadcrumb_bg.jpg">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb-content text-center">
                <h2>Listing</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Simple Listing – List View</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb-area-end --> */}

      {/* <!-- inventory-list-area --> */}
      <section class="inventory-list-area gray-lite-bg pt-50 pb-120">
        <div class="container">
          <div class="inventory-meta-wrap mb-50">
            <div class="row align-items-center">
              <div class="col-xl-8 col-lg-7 col-md-6">
                <div class="inventory-top-meta">
                  <ul>
                    <li class="find">Total Cars Find : <span>{inventory.length}</span></li>
                    <li class="filter d-none d-lg-flex">
                      {/* <a href="#"><i class="flaticon-menu-1"></i></a> */}
                      <a class="active" href="#"><i class="flaticon-menu-3"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-6">
                <form action="#" class="inventory-short-meta">
                  <label>Sort by :</label>
                  <select name="name" class="selected">
                    <option value="">New Listed : Newest</option>
                    <option value="">Old Listed : Oldest</option>
                    <option value="">Price High to Low</option>
                    <option value="">Price Low to High</option>
                    <option value="">Most Popular</option>
                  </select>
                </form>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-7 col-md-9">
              {
                // console.log("inventory ",inventory)
                (inventory.length > 0)?
                inventory.map((prd, index) => {
                  // console.log(prd.model)
                  // console.log("TEST ",prd.makeData[0].makeTitle)
                  // console.log("TEST ",prd.modelData[0]?.categoryTitle)
                  return <div class="inventory-list-item">
                    <div class="inventory-list-thumb">
                      {/* <img src={imageRootPath + prd.mainImage} alt="" /> */}
                      <img src={prd.images} alt="" />
                      <ul class="inv-thumb-meta">
                        <li><a target="_blank" href={prd.images} class="popup-image" data-toggle="tooltip" data-placement="top" data-original-title="View Image"><i class="fas fa-camera"></i></a></li>
                        {/* <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Add to Favorite"><i class="fas fa-star"></i></a></li> */}
                        <li><a href={"/inventory-details/" + prd._id} data-toggle="tooltip" data-placement="top" data-original-title="Details"><i class="fas fa-plus"></i></a></li>
                      </ul>
                    </div>

                    <div class="inventory-list-content">
                      <div class="inv-content-top">
                        <ul>
                          <li class="option">
                            {prd.status == 'used' ?
                            <a href="#">Used</a>
                            :
                            <a class="new" href="#">New</a>
                            }
                          </li>
                          <li class="price">{(prd.price >0) ? '$'+prd.price:''}<span></span></li>
                        </ul>
                      </div>
                      
                      <h4><a href={"/inventory-details/" + prd._id}>{prd.make+" - "+prd.model}</a></h4>
                      <p class="location"><i class="fas fa-map-marker-alt"></i> carhunt.ca<br />778-302-9099</p>
                      <p class="location"><i class="far fa-clock"></i> {prd.options}</p>
                      <div class="inv-item-meta">
                        <ul>
                          <li class="call"><a href="tel:778-302-9099"><i class="fas fa-phone-alt"></i>Call</a></li>
                          {/* <li>Make : {prd.makeData[0]?.makeTitle}</li>
                          <li>Model : {prd.modelData[0]?.categoryTitle}</li>
                          <li>Year : {prd.year}</li> */}
                          <li>Make : {prd.make}</li>
                          <li>Model : {prd.model}</li>
                          <li>Year : {prd.year}</li>
                        </ul>
                      </div>
                      <br />
                      <h6><a target="_blank" href="/loan-calculator">Calculate Financing</a></h6>
                    </div>
                  </div>
                })
                : (<div class="inventory-list-item">No Cars available</div>)
              }
            </div>
            <div class="col-xl-4 col-lg-5 col-md-9">
              <InventoryRightBarSearch />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- inventory-list-area-end --> */}

      {/* <!-- footer-brand-area --> */}
      <FooterBrandArea />
      {/* <!-- footer-brand-area-end --> */}

    </main>
  );
}

export default Inventory;