import { combineReducers } from 'redux';
import { alert } from './alert.reducer';
import { language } from './language.reducer';
import { make } from './make.reducer';
import { model } from './model.reducer';
import { inventory } from './inventory.reducer';

const rootReducer = combineReducers({
  alert, 
  language,
  make,
  model,
  inventory
});

export default rootReducer;
