import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import FooterBrandArea from '../Common/Footer/FooterBrandArea';
import CarByCategory from '../Common/CarByCategory';

const LoanCalculator = ({ history }) => {

    return (
        <main>

            {/* <!-- breadcrumb-area --> */}
            <section class="breadcrumb-area breadcrumb-bg" data-background="assets/img/bg/breadcrumb_bg.jpg">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-content text-center">
                                <h2>Auto Loan Calculator</h2>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">Loan Calculator</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- breadcrumb-area-end --> */}

            {/* <!-- loan-calculator-area --> */}
            <section class="loan-calculator-area pb-10">
                <div class="container">
                    <div class="calculator-loan">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form loan-calculator-form">

                                </div>
                                {/* <ul class="loan-terms-conditions">
                                    <li>
                                        <i class="far fa-check-square"></i>
                                        <p>There are many variations of passaes of orem ipsum available, but the majority have in some form, by ipsum injected</p>
                                    </li>
                                    <li>
                                        <i class="far fa-check-square"></i>
                                        <p>Placlder text commonly used detrate theyjlos visual form amet.</p>
                                    </li>
                                    <li>
                                        <i class="far fa-check-square"></i>
                                        <p>consectetur ading elitmod tempor used detrate theyjlos visual.</p>
                                    </li>
                                </ul> */}
                            </div>
                            <div class="col-md-6">
                                <div class="loan-result-wrap">
                                    <div class="section-title text-center mb-30">
                                        <h2 class="title">your estimated <span>amount</span></h2>
                                        <div class="line"><img src="assets/img/images/title_line.png" alt="" /></div>
                                    </div>
                                    <div class="results"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- loan-calculator-area-end --> */}

            {/* <!-- car-category-area --> */}
            {/* <section class="car-category-area pt-10 pb-120">
                <CarByCategory />
            </section> */}
            {/* <!-- car-category-area-end --> */}

            {/* <!-- footer-brand-area --> */}
            <FooterBrandArea />
            {/* <!-- footer-brand-area-end --> */}

        </main>
    );
}

export default LoanCalculator;