import React from 'react'
import { Nav } from 'react-bootstrap';
import { history } from '../../../helpers';

export default function FooterBrandArea() {

    return (
        <div class="footer-brand-area ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-6 order-2 order-md-0">
                        <div class="footer-logo" style={{backgroundColor: 'cornsilk'}}>
                            <a href="/"><img src="/assets/img/logo/highlighted-contact_number.png" alt="" /></a>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <div class="row footer-brand-active">
                            <div class="col">
                                <div class="footer-brand-item">
                                    <a href="#"><img src="/assets/img/brand/footer_brand01.png" alt="" /></a>
                                </div>
                            </div>
                            <div class="col">
                                <div class="footer-brand-item">
                                    <a href="#"><img src="/assets/img/brand/footer_brand02.png" alt="" /></a>
                                </div>
                            </div>
                            <div class="col">
                                <div class="footer-brand-item">
                                    <a href="#"><img src="/assets/img/brand/footer_brand03.png" alt="" /></a>
                                </div>
                            </div>
                            <div class="col">
                                <div class="footer-brand-item">
                                    <a href="#"><img src="/assets/img/brand/footer_brand04.png" alt="" /></a>
                                </div>
                            </div>
                            <div class="col">
                                <div class="footer-brand-item">
                                    <a href="#"><img src="/assets/img/brand/footer_brand05.png" alt="" /></a>
                                </div>
                            </div>
                            <div class="col">
                                <div class="footer-brand-item">
                                    <a href="#"><img src="/assets/img/brand/footer_brand03.png" alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
