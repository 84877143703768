export const inventoryConstants = {

    MAKE_GET_ACTIVE_REQUEST:'MAKE_GET_ACTIVE_REQUEST',
    MAKE_GET_ACTIVE_SUCCESS:'MAKE_GET_ACTIVE_SUCCESS',
    MAKE_GET_ACTIVE_FAILURE:'MAKE_GET_ACTIVE_FAILURE',

    CATEGORY_GET_ACTIVE_REQUEST:'CATEGORY_GET_ACTIVE_REQUEST',
    CATEGORY_GET_ACTIVE_SUCCESS:'CATEGORY_GET_ACTIVE_SUCCESS',
    CATEGORY_GET_ACTIVE_FAILURE:'CATEGORY_GET_ACTIVE_FAILURE',
    
    GET_INVENTORY_LIST_REQUEST:'GET_INVENTORY_LIST_REQUEST',
    GET_INVENTORY_LIST_SUCCESS:'GET_INVENTORY_LIST_SUCCESS',
    GET_INVENTORY_LIST_FAILURE:'GET_INVENTORY_LIST_FAILURE',

    SINGLE_PRODUCT_GET_REQUEST:'SINGLE_PRODUCT_GET_REQUEST',
    SINGLE_PRODUCT_GET_SUCCESS:'SINGLE_PRODUCT_GET_SUCCESS',
    SINGLE_PRODUCT_GET_FAILURE:'SINGLE_PRODUCT_GET_FAILURE',
}