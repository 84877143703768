import React from 'react'

const Menu = () => {

    return (
        <>
            <div id="sticky-header" class="main-header menu-area">
                <div class="container custom-container">
                    <div class="row">
                        <div class="col-12">
                            <div class="mobile-nav-toggler"><i class="fas fa-bars"></i></div>
                            <div class="menu-wrap">
                                <nav class="menu-nav show">
                                    <div class="logo">
                                        <a href="/" class="sticky-logo-none"><img src="/assets/img/logo/highlighted-contact_number.png" alt="Logo" /></a>
                                        <a href="/" class="sticky-logo-active"><img src="/assets/img/logo/highlighted-contact_number.png" alt="Logo" /></a>
                                    </div>
                                    <div class="navbar-wrap main-menu d-none d-lg-flex">
                                        <ul class="navigation">
                                            <li class="active dropdown"><a href="/">Home</a></li>
                                            <li class="dropdown"><a href="/about-us">About Us</a></li>
                                            <li class="dropdown"><a href="/inventory">INVENTORY</a></li>
                                            <li class="dropdown"><a href="/loan-calculator">Loan Calculator</a>
                                            </li>
                                            <li class="dropdown"><a href="#">Other Sites</a>
                                                <ul class="submenu">
                                                    <li><a target='_blank' href="https://gomotor.ca/">Gomotor</a></li>
                                                    <li><a target='_blank' href="http://dtkcrm.ca/">DTK CRM</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="/contact-us">Contact</a></li>
                                            <li class="dropdown"><a href="/sell-car">Sell your car</a>
                                            {/* <li class="dropdown"><a href="/faq">FAQ</a> */}
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div class="header-action d-none d-md-block">
                                        <ul>
                                            <li class="header-shop-cart"><a href="#"><i class="fas fa-shopping-basket"></i><span>0</span></a>
                                                <ul class="minicart">
                                                    <li class="d-flex align-items-start">
                                                        <div class="cart-img">
                                                            <a href="#">
                                                                <img src="/assets/img/product/cart_p01.jpg" alt="" />
                                                            </a>
                                                        </div>
                                                        <div class="cart-content">
                                                            <h4>
                                                                <a href="#">Volvo S60 Cross Country</a>
                                                            </h4>
                                                            <div class="cart-price">
                                                                <span class="new">$229.9</span>
                                                                <span>
                                                                    <del>$229.9</del>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="del-icon">
                                                            <a href="#">
                                                                <i class="far fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li class="d-flex align-items-start">
                                                        <div class="cart-img">
                                                            <a href="#">
                                                                <img src="/assets/img/product/cart_p02.jpg" alt="" />
                                                            </a>
                                                        </div>
                                                        <div class="cart-content">
                                                            <h4>
                                                                <a href="#">Mercedes-Benz GLE Coupe</a>
                                                            </h4>
                                                            <div class="cart-price">
                                                                <span class="new">$229.9</span>
                                                                <span>
                                                                    <del>$229.9</del>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="del-icon">
                                                            <a href="#">
                                                                <i class="far fa-trash-alt"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="total-price">
                                                            <span class="f-left">Total:</span>
                                                            <span class="f-right">$239.9</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="checkout-link">
                                                            <a href="#">Shopping Cart</a>
                                                            <a class="red-color" href="#">Checkout</a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="header-btn"><a href="contact.html" class="btn">get a quote</a></li>
                                            <li class="header-search"><a href="#" data-toggle="collapse" data-target="#collapse-search-body" aria-expanded="false" aria-controls="collapse-search-body"><i class="fas fa-search"></i></a></li>
                                        </ul>
                                    </div> */}
                                </nav>
                            </div>

                            <div class="mobile-menu">
                                <div class="menu-backdrop"></div>
                                <div class="close-btn"><i class="fas fa-times"></i></div>

                                <nav class="menu-box">
                                    <div class="nav-logo"><a href="/"><img src="/assets/img/logo/highlighted-contact_number.png" alt="" title="" /></a>
                                    </div>
                                    <div class="menu-outer">

                                    </div>
                                    <div class="social-links">
                                        <ul class="clearfix">
                                            <li><a href="#"><span class="fab fa-twitter"></span></a></li>
                                            <li><a href="#"><span class="fab fa-facebook-square"></span></a></li>
                                            <li><a href="#"><span class="fab fa-pinterest-p"></span></a></li>
                                            <li><a href="#"><span class="fab fa-instagram"></span></a></li>
                                            <li><a href="#"><span class="fab fa-youtube"></span></a></li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="collapse-search-body" class="collapse-search-body collapse">
                    <div class="search-body">
                        <div class="container custom-container">
                            <form action="#">
                                <div class="form-item">
                                    <input name="search" placeholder="Type here..." />
                                    <button type="submit"><i class="fas fa-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Menu;