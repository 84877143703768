export const apiConstants = {
    GET_USERS: '/getusers',
    USER_SIGNUP: '/signup',
    USER_LOGIN: '/login',
    USER_ACCOUNT_ACTIVATION : '/activate-account',
    RESEND_EMAIL: '/resend-email',
    FORGOT_PASSWORD: '/forgot-password',
    CONTACT_US: '/contact-us',
    SELL_CAR: '/sell-car',
    DEMO:'/demo',
    SUBSCRIBE_NEWSLETTER : '/newsLetter/add-subscriber',
    GET_ALL_ACTIVE_CATEGORIES: '/get-active-categories',
    GET_ALL_ACTIVE_MAKES: '/get-active-makes',
    GET_PRODUCT_LIST: "/product/get-products-for-front-website",
    GET_SINGLE_PRODUCT: "/product/get-single-product",
};
