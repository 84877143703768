import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import FooterBrandArea from '../Common/Footer/FooterBrandArea';

const AboutUs = ({ history }) => {
  const [siteConstants, setSiteConstants] = useState([])

  const { language } = useSelector(
    state => ({
      language: state.language
    })
  );

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }
  }, [language])

  const featuredCategory = [
    { 'name': 'Marketing Strategy', 'img': '/assets/images/target.png' },
    { 'name': 'Project Branding', 'img': '/assets/images/certified.png' },
    { 'name': 'Setup a Dealers', 'img': '/assets/images/gear.png' },
    { 'name': 'Increase Brand Values', 'img': '/assets/images/money-w.png' },
  ]

  return (
    <main>

            {/* <!-- breadcrumb-area --> */}
            <section class="breadcrumb-area breadcrumb-bg" data-background="assets/img/bg/breadcrumb_bg.jpg">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-content text-center">
                                <h2>About Our History</h2>
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- breadcrumb-area-end --> */}

            {/* <!-- inner-about-area --> */}
            <section class="inner-about-area gray-lite-bg pt-40 pb-60">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="inner-about-img mb-75">
                                <img src="assets/img/carhunt/2025_Tahoe.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-10">
                            <div class="inner-about-content mb-60">
                                <div class="section-title mb-25">
                                    <span class="sub-title">About Our Company</span>
                                    <h2 class="title">OUR MISSION IS SIMPLE</h2>
                                    <div class="line"><img src="assets/img/images/title_line.png" alt="" /></div>
                                </div>
                                <h6>Who We Are? </h6>
                                <p>CarHunt.ca is an online classified dedicated to buyers and sellers in Canada Taking a completely online approach, CarHunt.ca gives buyers the opportunity to view 1000’s of vehicles for sale. This approach gives consumers far greater choice and the opportunity to find their new ride.</p><br />
                                <p>
For sellers, our audience provides tens of thousands of potential buyers. As CarHunt.ca is promoted Canada wide, sellers throw a far greater net to potential buyers and no longer have to rely on local town buyers. With this greater exposure, sellers can enjoy even greater success.</p><br />
<p>
CarHunt.ca is designed to facilitate the in-expensive, rapid publishing of car advertisements and to be flexible enough to meet the needs of auto sellers. The site allows private advertisers and dealers to display their cars in a consistent way whilst providing both freedom of content and inclusion of photographs.</p><br />
<p>
­­­­­Consumers can browse through all adverts or select a narrowed range of adverts with our easy to use but powerful search engine.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-8">
                        <p>Sellers details are provided on every advert.</p>
                        <p><strong>All buyers benefit from:</strong></p>
                        <ul>
<li>A constantly changing range of stock from a variety of sellers.</li>
<li>Standard and detailed information on each car, usually with a photograph.</li>
<li>Powerful search engines to help you find exactly what you are looking for.</li>
<li>Easy contact with sellers by email directly from the advert and with contact details.</li>
</ul> <br/>
<p><strong>For all sellers we offer:</strong></p>
<ul>
<li>Efficient advertising rates.</li>
<li>Enquire about our free account.</li>
<li>Easy, rapid entry of your adverts – place an advert in minutes, completely online.</li>
<li>Adverts with your own detailed wording and an optional photograph.</li>
<li>Full control of your adverts – update your advert at any time.</li>
</ul>
                            {/* <div class="about-features-item">
                                <div class="icon">
                                    <i class="flaticon-fan"></i>
                                </div>
                                <div class="content">
                                    <h5>AIR CONDITIONING</h5>
                                    <p>Placlder text commonly used detrated theyjlos visual form amet</p>
                                </div>
                            </div>
                            <div class="about-features-item">
                                <div class="icon">
                                    <i class="flaticon-piston"></i>
                                </div>
                                <div class="content">
                                    <h5>dealership points</h5>
                                    <p>Placlder text commonly used detrated theyjlos visual form amet</p>
                                </div>
                            </div> */}
                        </div>
                        {/* <div class="col-lg-4 col-md-6 col-sm-8">
                            <div class="about-features-item">
                                <div class="icon">
                                    <i class="flaticon-belt"></i>
                                </div>
                                <div class="content">
                                    <h5>SUPER FAST car</h5>
                                    <p>Placlder text commonly used detrated theyjlos visual form amet</p>
                                </div>
                            </div>
                            <div class="about-features-item">
                                <div class="icon">
                                    <i class="flaticon-jerrycan"></i>
                                </div>
                                <div class="content">
                                    <h5>OIL CHANGES</h5>
                                    <p>Placlder text commonly used detrated theyjlos visual form amet</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {/* <!-- inner-about-area-end --> */}

            {/* <!-- about-area --> */}
            <div class="row">
                        <div class="col-12">
                            <div class="inner-about-img mb-75">
                                <img src="assets/img/carhunt/2025_Tahoe.jpg" alt="" />
                            </div>
                        </div>
                    </div>
            {/* <section class="about-area about-bg pt-120 pb-120" data-background="assets/img/bg/about_bg.jpg">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-img-wrap">
                                <div class="row align-items-end">
                                    <div class="col-sm-6">
                                        <div class="about-exp">
                                            <h2>49<span>+</span></h2>
                                        </div>
                                        <div class="about-img">
                                            <img src="assets/img/images/about_img01.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="about-img">
                                            <img src="assets/img/images/about_img02.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-content-wrap">
                                <div class="section-title white-title mb-40">
                                    <span class="sub-title">About Our Company</span>
                                    <h2 class="title">More for trade-in could increase car here<span>.</span></h2>
                                </div>
                                <div class="content">
                                    <div class="icon">
                                        <img src="assets/img/images/m.png" alt="" />
                                    </div>
                                    <p>Lorem ipsum, or lipsum as it is sometimes known dummy text used in laying out print, graphic or web designs. The passage
                                    is attributed to an unknown.</p>
                                </div>
                                <div class="about-list mb-40">
                                    <ul>
                                        <li>
                                            <div class="icon"><i class="fas fa-check"></i></div>
                                            Sometimes known dummy
                                        </li>
                                        <li>
                                            <div class="icon"><i class="fas fa-check"></i></div>
                                            Best solution for developing your business
                                        </li>
                                        <li>
                                            <div class="icon"><i class="fas fa-check"></i></div>
                                            Deserunt ad ipsum sint excepteur
                                        </li>
                                        <li>
                                            <div class="icon"><i class="fas fa-check"></i></div>
                                            Lorem sit occaecat ea proident excepteur
                                        </li>
                                    </ul>
                                </div>
                                <a href="#" class="btn">LEARN MORE</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- about-area-end --> */}

            {/* <!-- faq-area --> */}
            <div class="faq-area position-relative fix pt-50 pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-0 order-lg-2">
                            <div class="faq-img">
                                <img src="assets/img/carhunt/24sierra.jpg" alt="" />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="faq-wrap">
                                <div class="faq-set active">
                                    <a class="active" href="#">Do you offer any warranty? <i class="fas fa-minus"></i></a>
                                    <div class="content" style={{"display": "block"}}>
                                        <p>Lorem ipsum, or lipsum as it is sometimes known dummy text used in laying out print, graphic web
                                        The passage is attributed</p>
                                    </div>
                                </div>
                                <div class="faq-set">
                                    <a href="#">What is Auto Detailing? <i class="fas fa-plus"></i></a>
                                    <div class="content">
                                        <p>Lorem ipsum, or lipsum as it is sometimes known dummy text used in laying out print, graphic web
                                        The passage is attributed</p>
                                    </div>
                                </div>
                                <div class="faq-set">
                                    <a href="#">Is it urgent to fix an oil leak? <i class="fas fa-plus"></i></a>
                                    <div class="content">
                                        <p>Lorem ipsum, or lipsum as it is sometimes known dummy text used in laying out print, graphic web
                                        The passage is attributed</p>
                                    </div>
                                </div>
                                <div class="faq-set">
                                    <a href="#">How do I check my tire pressure? <i class="fas fa-plus"></i></a>
                                    <div class="content">
                                        <p>Lorem ipsum, or lipsum as it is sometimes known dummy text used in laying out print, graphic web
                                        The passage is attributed</p>
                                    </div>
                                </div>
                                <div class="faq-set">
                                    <a href="#">The passage is attributed unknown? <i class="fas fa-plus"></i></a>
                                    <div class="content">
                                        <p>Lorem ipsum, or lipsum as it is sometimes known dummy text used in laying out print, graphic web
                                        The passage is attributed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="faq-bg-dots" data-background="assets/img/bg/faq_dots_bg.jpg"></div>
            </div>
            {/* <!-- faq-area-end --> */}

            {/* <!-- footer-brand-area --> */}
            <FooterBrandArea />
            {/* <!-- footer-brand-area-end --> */}

        </main>
  );
}

export default AboutUs;