import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { inventoryAction } from '../../actions';
import { apiConstants } from '../../constants';

const InventoryRightBarSearch = () => {

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        vin: '', fuel_type: '', make: '', model: '', year: '', price: '', car_type: '', transmission: '',status:''
    });

    const [message, setMessage] = useState('')
    const [make, setMake] = useState([])
    const [model, setModel] = useState([]) //-- category

    const modelList = useSelector((state) => state.model);
    const makeList = useSelector((state) => state.make);
    // console.log("... ", makeList)
    // console.log("..modelList. ", modelList)
    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onChangeHandlerDDL = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (Object.keys(modelList).length > 0) {
            setModel(modelList.modelList.data);
        }
    }, [modelList])

    useEffect(() => {
        if (Object.keys(makeList).length > 0) {
            setMake(makeList?.makeList?.data);
        }
    }, [makeList])

    useEffect(() => {
        dispatch(inventoryAction.getAllActiveCategory(apiConstants.GET_ALL_ACTIVE_CATEGORIES));

        dispatch(inventoryAction.getAllActiveMake(apiConstants.GET_ALL_ACTIVE_MAKES));
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(inventoryAction.getInventoryList(apiConstants.GET_PRODUCT_LIST, formData));
        setMessage("Result updated.")
    }

    return (
        <>
            <aside class="inventory-sidebar">
                <div class="widget inventory-widget">
                    <div class="inv-widget-title mb-25">
                        <h5 class="title">Find Perfect Vehicles</h5>
                    </div>
                    <form action="#" class="sidebar-find-car">
                        {/* <div class="form-grp search-box">
                            <input type="text" placeholder="Search" />
                            <button><i class="fas fa-search"></i></button>
                        </div> */}
                        <div class="form-grp">
                            <i class="flaticon-placeholder-1"></i>
                            <input type="text" placeholder="VIN" name="vin" onChange={(e) => onChangeHandler(e)} />
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-grp">
                                    <i class="flaticon-gasoline-pump"></i>
                                    <select id='fuel_type' name="fuel_type" style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }} onChange={(e) => onChangeHandlerDDL(e)}>
                                        <option value="">Fuel</option>
                                        <option value="Petrol">Petrol</option>
                                        <option value="Diesel">Diesel</option>
                                        <option value="Gas">Gas</option>
                                        <option value="CNG">CNG</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-grp">
                                    <i class="far fa-clock"></i>
                                    <input type="text" name="year" placeholder="2024" onChange={(e) => onChangeHandler(e)} />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-grp">
                                    <i class="flaticon-car-4"></i>
                                    {/* <select name="name" class="selected">
                                        <option value="">Make</option>
                                        <option value="petrol">Petrol</option>
                                        <option value="disel">Disel</option>
                                        <option value="gas">Gas</option>
                                        <option value="cng">CNG</option>
                                    </select> */}
                                    <select name="make" id="make" onChange={(e) => onChangeHandlerDDL(e)} style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }}>
                                        <option value="">Select Make</option>
                                        {

                                            make?.map((cat, index) => {
                                                return <option value={cat.makeTitle}>{cat.makeTitle}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-grp">
                                    <i class="flaticon-car-4"></i>
                                    <select name="model" onChange={(e) => onChangeHandlerDDL(e)} style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }}>
                                        <option value="">Select Model</option>
                                        {
                                            model.map((cat, index) => {
                                                return <option value={cat.categoryTitle}>{cat.categoryTitle}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-grp">
                            <i class="flaticon-car-4"></i>
                            <select name="car_type" onChange={(e) => onChangeHandlerDDL(e)} style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }}>
                                <option value="">Car Type</option>
                                <option value="SUV">SUV</option>
                                <option value="Truck">Truck</option>
                                <option value="">Audi A8 Hybrid</option>
                                <option value="">Toyota T86LM</option>
                            </select>
                        </div>
                        {/* <div class="form-grp">
                            <i class="fas fa-dollar-sign"></i>
                            <select name="price" onChange={(e) => onChangeHandlerDDL(e)} style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }}>
                                <option value="">Price</option>
                                <option value="5000">$0 - $5000</option>
                                <option value="15000">$5000 - $15000</option>
                                <option value="25000">$15000 - $25000</option>
                                <option value="50000">$25000 - $50000</option>
                                <option value="750000">$50000 - $75000</option>
                                <option value="100000">$75000 - $100000</option>
                                <option value="500000">$100000+</option>
                            </select>
                        </div> */}
                        
                        <div class="form-grp">
                            <i class="flaticon-speedometer"></i>
                            <select name="status" style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }} onChange={(e) => onChangeHandlerDDL(e)}>
                                <option value="">Select Status</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                            </select>
                        </div>
                        <div class="form-grp">
                            <i class="flaticon-speedometer"></i>
                            <select name="transmission" onChange={(e) => onChangeHandlerDDL(e)} style={{ "width": "100%", "border": "1px solid #e1e1e1", "borderRadius": "6px", "fontSize": "14px", "padding": "12px 20px 12px 40px", "color": "#818080" }}>
                                <option value="">Transmission :</option>
                                <option value="Manual">Manual Transmission</option>
                                <option value="Gear">Gear Transmission</option>
                                <option value="Automatic">Automatic</option>
                            </select>
                        </div>
                        <button class="btn" onClick={(e) => onSubmit(e)}>Find Vehicles</button> 
                        <p style={{color: "red"}}>{message}</p>
                    </form>
                </div>

                <div class="widget inventory-widget">
                    <div class="inv-widget-title mb-25">
                        <h5 class="title">Featured Vehicles</h5>
                    </div>
                    <div class="sidebar-fv-active">
                        <div class="sidebar-fv-item" data-background="/assets/img/carhunt/listingpage/fe1.jpg">
                            {/* <div class="fv-top-tag">
                        <ul>
                          <li><a href="#">For Rent</a></li>
                          <li><a class="new" href="#">New Car</a></li>
                        </ul>
                      </div> */}
                            <div class="sidebar-fv-content">
                                <h5>$11,000<span></span></h5>
                                <p><a href="inventory-details.html">BMW 8 - Series 2 Door</a></p>
                            </div>
                        </div>
                        <div class="sidebar-fv-item" data-background="/assets/img/carhunt/listingpage/fe2.jpg">
                            {/* <div class="fv-top-tag">
                        <ul>
                          <li><a href="#">For Rent</a></li>
                          <li><a class="new" href="#">New Car</a></li>
                        </ul>
                      </div> */}
                            <div class="sidebar-fv-content">
                                <h5>$29,000<span></span></h5>
                                <p><a href="inventory-details.html">Audi - A4 Sedan XL</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="sidebar-loan-calculator">
                        <a href="/loan-calculator"><i class="fas fa-calculator"></i><span>Auto Loan Calculator</span></a>
                    </div>
                </div>
                {/* <div class="widget inventory-widget">
                  <div class="inv-widget-title mb-25">
                    <h5 class="title">Listed By Advisor</h5>
                  </div>
                  <div class="advisor-wrap mb-25">
                    <div class="advisor-thumb">
                      <img src="assets/img/images/sidebar_listed_img.png" alt="" />
                    </div>
                    <div class="advisor-info">
                      <h5><a href="#">Hamil Alexander.</a></h5>
                      <div class="phone">
                        <a href="tel:0987654321"><i class="flaticon-phone-call"></i> (123)456-7890</a>
                      </div>
                      <a href="author-profile.html" class="profile">Advisor Profile</a>
                    </div>
                  </div>
                  <form action="#" class="advisor-form">
                    <input type="text" placeholder="Your Name *" />
                    <input type="email" placeholder="Your Email *" />
                    <textarea name="message" placeholder="I'm interest in { Linting Single }"></textarea>
                    <button class="btn">Search</button>
                  </form>
                </div> */}
            </aside>
        </>
    )
}

export default InventoryRightBarSearch;